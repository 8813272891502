import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, deleteDoc, doc, getDocs } from 'firebase/firestore';
import { FaRegEdit } from 'react-icons/fa';
import { MdOutlineDeleteOutline } from 'react-icons/md';
import Buttons from '../../Common/Buttons';
import { db } from '../../firebase';
import { DeleteModal } from '../../Common/DeleteModal';
const iconClass = "text-xl";
const heading=  "py-4 text-xl font-bold text-gray-800 pl-4 p-10   bg-blue-100";
const tableData="border-b border-[#eee] py-3 px-4 ";

export const ViewPartnership = () => {
  const [partnershipData, setPartnershipData] = useState(null);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchPartnershipData = async () => {
      const partnershipCollection = collection(db, 'Partnership');
      const snapshot = await getDocs(partnershipCollection);
      const data = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setPartnershipData(data);
    };
    fetchPartnershipData();
  }, []);

  const handleDelete = (id) => {
    setSelectedItemId(id);
    setDeleteModalOpen(true);
  };

  const confirmDelete = async () => {
    try {
      const partnershipRef = doc(db, 'Partnership', selectedItemId);
      await deleteDoc(partnershipRef);
      setPartnershipData(partnershipData.filter(item => item.id !== selectedItemId));
    } catch (error) {
      console.log('Error deleting document', error);
    } finally {
      setDeleteModalOpen(false);
    }
  };

  const handleEdit = (id) => {
    try {
      const foundPartnership = partnershipData.find((category) => category.id === id);
      navigate(`/editpartnership/${id}`, { state: { editData: foundPartnership } });
    } catch (error) {
      console.log('Error navigating to edit page', error);
    }
  };

  return (
    <div className='ml-40 px-5 pt-6 pb-2.5'>
    <div className='flex justify-end mb-10'>
      <Buttons type="button" variant="secondary" to='/addpartnership'>
        Add
      </Buttons>
    </div>
    <div className="rounded-sm  bg-white  shadow-default sm:px-7.5 xl:pb-1">
      <div className="overflow-x-auto">
        <table className="w-full table-auto border-collapse">
          <thead>
            <tr className="bg-gray-200 text-left dark:bg-meta-4">
              <th className={heading}>
                Upload Image
              </th>
              <th className={heading}>
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {partnershipData && partnershipData.length > 0 ? (
              partnershipData.map((partnershipData, key) => (
                <tr key={key} className={(key % 2 === 0) ? 'bg-gray-100 dark:bg-gray-200' : ''}>
                  <td className={tableData}>
                  <img
                        alt="founderimage"
                        src={partnershipData.imageUrl}
                        className="h-20 w-20  object-cover border border-gray-100"
                      />
                    </td>
                    <td className={tableData}>
                      <div className="flex items-center space-x-3.5">
                        <span className="hover:text-primary cursor-pointer">
                          <FaRegEdit className={iconClass} onClick={() => handleEdit(partnershipData.id)} />
                        </span>
                        <span className="hover:text-primary cursor-pointer" onClick={() => handleDelete(partnershipData.id)}>
                          <MdOutlineDeleteOutline className={iconClass}/>
                        </span>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="2" className='text-center text-[20px] py-5'>
                    No Data Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {isDeleteModalOpen && (
        <DeleteModal
          isModalOpen={isDeleteModalOpen}
          closeModal={() => setDeleteModalOpen(false)}
          titleName={`item ${selectedItemId}`}   // Pass the appropriate title here
          handleDelete={confirmDelete}
        />
      )}
    </div>
  );
};
