import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import bg from "../assets/images/banner/bnr4.jpg";
import GallerySlider from "../components/Fundraiser/GallerySlider";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";
const FundraiserDetail = () => {
  const { id } = useParams();
  const [projectData, setProjectData] = useState([]);
  const [latestprojectData, setLatestProjectData] = useState([]);

  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        const ProjectCollection = collection(db, "Project");
        const snapshot = await getDocs(ProjectCollection);
        const data = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        data.sort((a, b) => new Date(b.date) - new Date(a.date));
        const latestProject = data.slice(0, 4);
        setLatestProjectData(latestProject);
        setProjectData(data);
      } catch (error) {
        console.error("Error fetching project data:", error);
      }
    };
    fetchProjectData();
  }, []);

  // Log the entire projectData array
  console.log(projectData);
  console.log(latestprojectData);

  // Log the projectId from the route parameters
  console.log(id);

  // Find the selected project based on the projectId
  const selectedProject = projectData.find((project) => project.id === id);

  // Log the selected project

  return (
    <>
      <div className="page-content bg-white overflow-x-hidden">
        <div className="relative">
          <img
            src={bg}
            alt=""
            className="w-full h-[20rem] md:h-[32rem] object-cover"
          />
          {/* Background color overlay */}
          <div className="absolute inset-0 bg-[#6b696a] bg-opacity-50"></div>
          {/* Content */}
          <span className="absolute md:bottom-[12rem] bottom-[8rem] flex bg-transparent w-full md:h-20 p-2 rounded text-center text-black font-extrabold text-2xl md:text-6xl justify-center ">
            <div className="dz-bnr-inr-entry  w-full">
              <nav aria-label="breadcrumb" className="breadcrumb-row">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item active">Fundraiser Detail</li>
                </ul>
              </nav>
            </div>
          </span>
        </div>

        <div>
          <section className="content-inner-2 ">
            <div className="container">
              <div className="row">
                <div className="col-xl-8 col-lg-8 m-b30 ">
                  <div className="fundraiser-single ">
                    <div className="swiper  fundraiser-gallery-wrapper ">
                      <GallerySlider />
                    </div>
                    <h2 className="title ">{selectedProject?.title}</h2>
                    <p>{selectedProject?.description}</p>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4">
                  <aside className="side-bar sticky-top">
                    {/* <!-- Fundraiser Post --> */}
                    <div className="widget style-1 recent-posts-entry">
                      <div className="widget-title">
                        <h5 className="title">Fundraiser Post</h5>
                      </div>
                      <div className="widget-post-bx">
                        {latestprojectData.map((data, ind) => (
                          <div className="widget-post clearfix" key={ind}>
                            <div className="dz-media">
                              <img
                                src={data.imageUrl1}
                                alt=""
                                className="w-20 h-20 object-cover"
                              />
                            </div>
                            <div className="dz-info">
                              <h6 className="title f">
                                <Link to={`/fundraiser-detail/${data.id}`}>
                                  {data.title}
                                </Link>
                              </h6>
                              <div className="dz-meta">
                                <ul>
                                  <li className="post-date">
                                    {data.description.slice(0, 6)}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </aside>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default FundraiserDetail;
