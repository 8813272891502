import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import React, { useEffect, useState } from 'react'
import { db, storage } from '../../firebase';
import { doc, getDoc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../Common/Loader';

export const EditCertificate = () => {
    const [form, setForm] = useState({
        imageUrl1: '',
        // Add other fields from your document here
    });
    const { id } = useParams();
    const [error, setError] = useState({});
    const [file1, setFile1] = useState(null);
    const [isSubmit, setIsSubmit] = useState(false);
    const [uploadedImageUrl, setUploadedImageUrl] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true); // Show loader
            try {
                const certificateRef = doc(db, 'Certificate', id);
                const docSnapshot = await getDoc(certificateRef);

                if (docSnapshot.exists()) {
                    const data = docSnapshot.data();
                    setForm(data);

                    // If imageUrl1 exists in the data, set it in the state
                    if (data.imageUrl1) {
                        setUploadedImageUrl(data.imageUrl1);
                    }
                } else {
                    console.error('Document does not exist');
                }
            } catch (error) {
                console.error('Error fetching document: ', error);
            } finally {
                setIsLoading(false); // Hide loader
            }
        };

        fetchData();
    }, [id]);

    useEffect(() => {
        const uploadFile = async (file, imageUrlKey) => {
            const storageRef = ref(storage, file.name);
            const uploadTask = uploadBytesResumable(storageRef, file);

            try {
                await uploadTask;

                const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

                setForm((prevForm) => ({ ...prevForm, [imageUrlKey]: downloadURL }));
                setUploadedImageUrl(downloadURL);
            } catch (error) {
                console.error('Error uploading file: ', error);
            }
        };

        if (file1) {
            uploadFile(file1, 'imageUrl1');
        }
    }, [file1]);

    const handleChangeFile = (e) => {
        const newFile = e.target.files[0];
        setFile1(newFile);

        // Update the uploaded image URL state to show the new image
        const objectURL = URL.createObjectURL(newFile);
        setUploadedImageUrl(objectURL);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const isEmptyField = Object.values(form).some((value) => value === '');
        if (isEmptyField) {
            setError({ submit: 'All Fields are required' });
            alert('All fields are required');
            return;
        }

        setIsSubmit(true);

        try {
            const certificateRef = doc(db, 'Certificate', id);

            setIsLoading(true); // Show loader before API call

            await updateDoc(certificateRef, {
                ...form,
                timestamp: serverTimestamp(),
            });

     
            navigate('/viewcertificate');
        } catch (error) {
            console.error('Error updating document: ', error);
        } finally {
            setIsLoading(false); // Hide loader after API call
        }
    };

    return (
        <main className="mt-20 ml-20">
            <h1 className="w-fit mx-auto text-blue-700">Edit Certificate</h1>
            <span className="flex w-[120px] h-[2px] bg-[#e1e1e1] mx-auto mb-4">
                <em className="w-[60px] h-[2px] bg-blue-700 mx-auto" />
            </span>
            <form className="w-[80%] h-auto p-10 bg-slate-50 shadow-lg mx-auto">
                <div className="flex flex-wrap  gap-4">
                <label htmlFor="fileInput" className="cursor-pointer flex items-center justify-center border border-dashed border-[#1475cf] min-h-[120px] min-w-[120px] max-h-[120px] max-w-[120px]">
                <input
                            type="file"
                            id="fileInput"
                            accept="image/*"
                            className="hidden"
                            onChange={handleChangeFile}
                        />
                       <span className="text-[12px] text-center font-bold">(Choosefile)</span>
                    </label>
                    {uploadedImageUrl && (
                        <img
                            src={uploadedImageUrl}
                            alt="Uploaded"
                            className="w-[120px] h-[120px] border border-blue-600 object-cover"
                        />
                    )}
                </div>

                <button
                    onClick={handleSubmit}
                    type="submit"
                    className="bg-blue-400 py-2 px-20 rounded text-black mt-5"
                >
                    {isLoading ? <Loader /> : 'Update'}
                </button>
            </form>
        </main>
    );
};
