import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import large from "../assets/images/blog/large/pic1.jpg";
import blog2 from "../assets/images/blog/blog-grid/pic2.jpg";
import blog1 from "../assets/images/blog/blog-grid/pic1.jpg";
import test from "../assets/images/testimonials/pic2.jpg";
import avat1 from "../assets/images/avatar/avatar1.jpg";
import avat2 from "../assets/images/avatar/avatar2.jpg";
import avat3 from "../assets/images/avatar/avatar3.jpg";
import avat4 from "../assets/images/avatar/avatar4.jpg";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import Blog from "../pages/Blog";
export const CommentBlog = (props) => {
  return (
    <>
      <div className="comment-body">
        <div className="comment-author vcard">
          <img className="avatar photo" src={props.image} alt="" />
          <cite className="fn">{props.title}</cite>
        </div>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p>
        <div className="reply">
          <Link to={"#"} className="comment-reply-link">
            <i className="fa fa-reply"></i>Reply
          </Link>
        </div>
      </div>
    </>
  );
};


 
  const BlogDetailsLeftBar = () => {
    const [BlogData, setBlogData] = useState([]);
    const { id } = useParams();
  
    useEffect(() => {
      const fetchNews = async () => {
        try {
          const BlogCollection = collection(db, "News");
          const snapshot = await getDocs(BlogCollection);
          const Newsdata = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setBlogData(Newsdata);
        } catch (error) {
          console.error("Error fetching project data:", error);
        }
      };
      fetchNews();
    }, [])
    const selectedBlog=BlogData.find((blog)=> blog.id===id)
    console.log(selectedBlog);
    console.log(id);
    console.log(BlogData);
  return (
    <>
      <div className="dz-card blog-single sidebar">
    
          <div>
            <div className="dz-media">
              <img src={selectedBlog?.imageUrl1} alt="" className="w-full h-[18rem] md:h-[30rem]" />
            </div>

            <div className="dz-info" >
              <div className="dz-meta">
                <ul>
                  <li className="post-date">
                    <i className="fa-solid fa-calendar-days"></i> {selectedBlog?.date}
                  </li>
                  <li className="post-author">
                    <Link to={"#"}>
                      <i className="fa-solid fa-user"></i> {selectedBlog?.author}
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="dz-post-text">
                <h1 className="dz-title">{selectedBlog?.title}</h1>
                <p>{selectedBlog?.description}</p>
              </div>
            </div>
          </div>
     
      </div>
    </>
  );
};

export default BlogDetailsLeftBar;
