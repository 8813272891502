import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
//import { LazyLoadImage } from 'react-lazy-load-image-component';

// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";

// import Swiper core and required modules
import { Autoplay, Pagination } from "swiper/modules";
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';

//SwiperCore.use([EffectCoverflow,Pagination]);

const dataBlog = [
  { icon: "flaticon-raw-food", title: "Health" },
  { icon: "flaticon-responsibility", title: "Care" },
  { icon: "flaticon-vr-glasses", title: "Technology" },
  { icon: "flaticon-open-book", title: "Education" },
  { icon: "flaticon-video", title: "Videos" },
  { icon: "flaticon-like-1", title: "Fashion" },
  { icon: "flaticon-transformation", title: "Design" },
  { icon: "flaticon-doctor-bag", title: "Medical" }
];

const CategoriesSlider = () => {
  //const navigationPrevRef = React.useRef(null)
  //const navigationNextRef = React.useRef(null)
  const paginationRef = React.useRef(null)
  const [category, setCategory] = useState([]);
  useEffect(() => {
    const fetchCategory = async () => {
      const CategoryCollection = collection(db, "Category");
      const snapshot = await getDocs(CategoryCollection);
      const categoryData = snapshot.docs.map(doc => doc.data());
      console.log(categoryData,'category data');
      setCategory(categoryData)
    }
    fetchCategory();
  }, [])
  console.log(category,'category')
  return (
    <>
      <Swiper className="categories-swiper"
        speed={1000}
        parallax={true}
        slidesPerView={8}
        spaceBetween={30}
        loop={false}
        autoplay={{
          delay: 2000,
        }}
        //pagination={{ clickable: true }}
        onSwiper={(swiper) => {
          setTimeout(() => {
            //swiper.params.navigation.prevEl = navigationPrevRef.current
            //swiper.params.navigation.nextEl = navigationNextRef.current
            //swiper.navigation.destroy()
            //swiper.navigation.init()
            //swiper.navigation.update()
          })
        }}
        // pagination= {{
        //     el= {.swiper-pagination}
        //     clickable= {true}
        // }}
        modules={[Autoplay, Pagination]}
        breakpoints={{
          1191: {
            slidesPerView: 8,
          },
          992: {
            slidesPerView: 6,
          },
          768: {
            slidesPerView: 5,
          },
          575: {
            slidesPerView: 3,
          },
          320: {
            slidesPerView: 2,
          },
        }}
      >

        {category.map((category, i) => (
          <SwiperSlide key={i}>
            <div className="position-relative" data-wow-delay="0.2s">
              <div className="">
                <Link to={`/project-categories/${category.categoryId}`} className="">
                  <img src={category.imageUrl1} alt="" className="full w-40 h-36 object-cover" />
                </Link>
              </div>
              <div className="icon-content absolute top-0  mr-6 bottom-0 d-flex flex-column justify-content-end text-center  ">
                <h6 className="font-bold text-capitalize bg-red-100 text-white  w-full  p-1 bg-opacity-20 text-center ">
                  <Link to={`/project-categories/${category.categoryId}`}>
                    {category.categoryname}
                  </Link>
                </h6>
                {/* Add other category details if needed */}
              </div>
            </div>
          </SwiperSlide>
        ))}

        <div className="swiper-pagination style-1 text-center" ref={paginationRef}></div>
      </Swiper>
    </>
  );
};


export default CategoriesSlider;
