export const Founder=[
    {
    name:"name",
    type:"text",
    placeH:"Name",
    formName:"Name"
},
{
    name:"position",
    type:"text",
    placeH:"position",
    formName:"Position"
}]

export const News=[
    {
        name:"username",
        type:"text",
        placeH:"Username",
      
    },
    {
        name:"title",
        type:"text",
        placeH:"Title",
    
    },
    {
        name:"category",
        type:"text",
        placeH:"Category",
       
    },
]
export const Testimonial=[
    {
        name:"author",
        type:"text",
        placeH:"Author Name",
    },
    {
        name:"position",
        type:"text",
        placeH:"Position",
    }
]
export const categories=[{
    name:"categoryname",
    type:'text',
    placeH:"Category Name",
},
]
export const HomeBanner=[
    {
    name:"Title",
    type:'text',
    placeH:"Title",
},

]
export const CounterData=[{
    name:"title",
    type:"text",
    placeH:"Title"
},
{
    name:"number",
    type:"number",
    placeH:""
}
]
export const RecentProject=[
    {
    name:"title",
    type:"name",
    placeH:"Enter Title"
},
{
    name:"category",
    type:"name",
    placeH:"Enter Category"
},
{
    name:"author",
    type:"name",
    placeH:"Enter author name"
},
{
    name:"no_campaign",
    type:"number",
    placeH:"Enter no of campaign"
},
{
    name:"Address",
    type:"name",
    placeH:"Enter Address"
},


]
export const SuccessStory=[
    {
    name:"title",
    type:"name",
    placeH:"Enter Title"
},
{
name:"category",
    type:"name",
    placeH:"Enter Category"
},
{
    name:"author",
    type:"name",
    placeH:"Enter author name"
},
{
    name:"no_campaign",
    type:"number",
    placeH:"Enter no of campaign"
},
{
    name:"Address",
    type:"name",
    placeH:"Enter Address"
},



]
