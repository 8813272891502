import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import { collection, deleteDoc, doc, getDocs } from "firebase/firestore";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Buttons from "../../Common/Buttons";
import { FaRegEye } from "react-icons/fa";
import { DeleteModal } from "../../Common/DeleteModal";
// Make sure to import the DeleteModal component here

const iconClass = "text-xl";
const tableData = "border-b border-[#eee] py-3 px-4 ";
const heading =
  "py-4 text-xl font-bold text-gray-800 pl-4 p-10   bg-blue-100";

export const ViewProject = () => {
  const [ProjectData, setProjectData] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProjectData = async () => {
      const NewsCollection = collection(db, "Project");
      const snapshot = await getDocs(NewsCollection);
      const data = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setProjectData(data);
    };
    fetchProjectData();
  }, []);

  const handleDelete = (id) => {
    setSelectedItemId(id);
    setIsDeleteModalOpen(true);
  };

  const confirmDelete = async () => {
    try {
      const ProjectRef = doc(db, "Project", selectedItemId);
      await deleteDoc(ProjectRef);
      setProjectData(ProjectData.filter((item) => item.id !== selectedItemId));
      setIsDeleteModalOpen(false);
    } catch (error) {
      console.log("error deleting document", error);
    }
  };

  const handleEdit = (id) => {
    try {
      const foundProject = ProjectData.find((news) => news.id === id);
      navigate(`/editproject/${id}`, { state: { editData: foundProject } });
    } catch (error) {
      console.log("error navigating to edit page", error);
    }
  };

  return (
    <>
      <div className="ml-40  px-5 pt-6 pb-2.5 ">
        <div className="flex justify-end mb-10">
          <Buttons type="button" variant="secondary" to="/addproject">
            Add
          </Buttons>
        </div>
        <div className="rounded-sm  bg-white  shadow-default  dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <div className="overflow-x-auto  border  border-gray-100">
            <table className="w-full table-auto border-collapse  ">
              <thead>
                <tr className="bg-gray-200 text-left ">
                  <th className={heading}>Image</th>
                  <th className={heading}>Image2</th>
                  <th className={heading}>Author</th>
                  <th className={heading}>Category</th>
                  <th className={heading}>Title</th>
                  <th className={heading}>Address</th>
                  <th className={heading}>No_campaign</th>
                  <th className={heading}>Description</th>
                  <th className={heading}>Action</th>
                </tr>
              </thead>
              <tbody>
                {ProjectData && ProjectData.length > 0
                  ? ProjectData.map((ProjectData, key) => (
                      <tr
                        key={key}
                        className={key % 2 === 0 ? "bg-gray-100 " : ""}
                      >
                        <td className={tableData}>
                          <img
                            alt="founderimage"
                            src={ProjectData.imageUrl1}
                            className="h-24 w-24  object-img-cover border border-gray-100"
                          />
                        </td>
                        <td className={tableData}>
                          <img
                            alt="founderimage"
                            src={ProjectData.imageUrl2}
                            className="h-24 w-24  object-img-cover border border-gray-100"
                          />
                        </td>
                        <td className={tableData}>
                          <p className="text-black text-xl">
                            {ProjectData.author}
                          </p>
                        </td>
                        <td className={tableData}>
                          <p className="text-black text-xl">
                            {ProjectData.category}
                          </p>
                        </td>

                        <td className={tableData}>
                          <p className="text-black text-xl">
                            {ProjectData.title.slice(0, 10)}
                          </p>
                        </td>
                        <td className={tableData}>
                          <p className="text-black text-xl">
                            {ProjectData.Address}
                          </p>
                        </td>
                        <td className={tableData}>
                          <p className="text-black text-xl">
                            {ProjectData.no_campaign}
                          </p>
                        </td>
                        <td className={tableData}>
                          <p className="text-black  text-xl">
                            {ProjectData.description.slice(0, 30)}
                          </p>
                        </td>

                        <td className={tableData}>
                          <div className="flex items-center space-x-3.5">
                            <span className="hover:text-primary cursor-pointer">
                              <FaRegEye
                                className={iconClass}
                                onClick={() => handleEdit(ProjectData.id)}
                              />
                            </span>
                            <span
                              className="hover:text-primary cursor-pointer"
                              onClick={() => handleDelete(ProjectData.id)}
                            >
                              <MdOutlineDeleteOutline className={iconClass} />
                            </span>
                          </div>
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </div>
        </div>

        {/* Add the DeleteModal component here */}
        <DeleteModal
          isModalOpen={isDeleteModalOpen}
          closeModal={() => setIsDeleteModalOpen(false)}
          titleName={`Project item ${selectedItemId}`}  // Adjust the title or details accordingly
          handleDelete={confirmDelete}
        />
      </div>
    </>
  );
};
