export const MenuListArray2 = [
    {   
        title: 'Home',	
        to: './',
    },
    {
        title : "About Us",
        to : "/about-us"
    },
    {   
        title: 'Contact Us',	
        to: './contact-us',
    },
    {
        title : "Certificates",
        to : "/certificates"
    },
    // {
    //     title : "Terms And Condition",
    //     to : "/terms-and-condition"
    // },
    {
        title:"Project",
        to:"/project"
    },
    // {
    //     title:"Project Story",
    //     to:"/project-story"
    // },
   
    {
        title:"Blog",
        to:"/blog-grid"
    },
    {
        title:"Gallery",
        to:"/gallery"
    },

];