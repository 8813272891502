import React    ,{useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

//images
import pic from './../../assets/images/project/large/pic1.jpg';
import avatar from './../../assets/images/avatar/avatar1.jpg';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';

const StoryBlog = () => {
    const [SuccessData,setSuccessData]=useState([]);
    
    const [successnewData, setSuccessnewData] = useState([]);
    useEffect(() => {
        const fetchNews = async () => {
            const SuccessCollection = collection(db, 'SuccessStory');
            const snapshot = await getDocs(SuccessCollection);
            const successData = snapshot.docs.map(doc => doc.data());
            setSuccessData(successData);
    
            const sortedData = successData.sort((a, b) => new Date(a.date) - new Date(b.date));
            if (sortedData.length > 0) {
                setSuccessnewData([sortedData[0]]);
            }
        };
    
        fetchNews();
    }, []);
    console.log(successnewData)
    console.log(SuccessData[0]?.title)
    function isScrolledIntoView(elem){
        const spliBox = document.querySelectorAll(elem);
        
        spliBox.forEach(myFunction);
        function myFunction(item) {

            const docViewTop = window.scrollY;

            const docViewBottom = docViewTop + window.innerHeight;

            let elemTop = item.getBoundingClientRect().top + docViewTop;

            const elemBottom = elemTop + item.offsetHeight;

            if((elemBottom <= docViewBottom) && (elemTop >= docViewTop)){               
                item.classList.add('split-active');
            }
          }
    }
    window.addEventListener("scroll", () => {
        isScrolledIntoView('.split-box');
    });   
    return(
        <>
           
            <div className="row align-items-center">
                <div className="col-lg-8 col-md-12 wow fadeInUp">
                    <div className="section-head">
                        <h2 className="title font-bold">Read They Story With Quba</h2>
                       
                    </div>
                </div>
                <div className="col-lg-4 col-md-12 m-b30 text-end d-none d-lg-block wow fadeInUp" data-wow-delay="0.4s">
                    <Link to={"/project-story"} className="btn btn-primary btnhover2">View More <i className="fa-solid fa-angle-right ms-2"></i></Link>
                </div>
            </div>
            <div className="row align-items-center project-bx left   m-b30">
                <div className="col-lg-6 col-md-12 wow fadeInUp" data-wow-delay="0.2s">	
                    <div 
                        
                        //className={`dz-media split-box ${abc ? "split-active" : ""}`} 
                    
                        //id="content"
                    >
                        <div className='shadow-sm'>
                            <img src={successnewData[0]?.imageUrl1} alt="" className=" w-full md:h-[27rem] h-[14rem]  rounded-sm " />
                        </div>
                    </div> 
                </div>
                <div className="col-lg-6 col-md-12">
                    <div className="dz-content wow fadeInUp" data-wow-delay="0.4s">
                    {successnewData.length >0 && (
                        <div>
                        <h3 className="title m-b2 font-bold text-[20px] sm:text-[24px]">{successnewData[0]?.title}</h3>
                        <p className="m-b0 sm:font-[16px] text-[16px] sm:text-[16px] text-justify">{successnewData[0]?.description}</p>
                     
                        <div className="author-wrappper">
                            <div className="author-media">
                                <img src={successnewData[0]?.imageUrl2} alt="" className='w-full object-cover h-full ' /> 
                            </div>
                            <div className="author-content">
                                <div className="author-head">
                                    <h6 className=" text-[16px] font-normal">{successnewData[0]?.author}</h6>
                                  
                                </div>
                                <ul className="author-meta ">
                                    <li className="campaign">{successnewData[0]?.no_campaign}campaign</li>
                                    <li className="location  font-normal">{successnewData[0]?.Address}</li>
                                </ul>
                            </div>
                        </div>
                        </div>
                        )
                    }
                    </div>
                </div>
            </div>               


            {/* <div className="col-lg-6 col-md-12 wow fadeInUp" data-wow-delay="0.2s">	
                <div                     
                    className={`dz-media split-box`} 
                   // id="content"
                >
                    <div>
                        <img src={pic} alt="" className="app-1" />
                    </div>
                </div> 
            </div>
            <div className="col-lg-6 col-md-12 wow fadeInUp" data-wow-delay="0.2s">	
                <div                     
                    className={`dz-media split-box`} 
                   // id="content"
                >
                    <div>
                        <img src={pic} alt="" className="app-1" />
                    </div>
                </div> 
            </div> */}
        </>
    )
}
export default StoryBlog;