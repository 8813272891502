import React, { useEffect, useState } from 'react';
import { db, storage } from '../../firebase';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';
import Loader from '../../Common/Loader';
import Modal from '../../Common/Modal';

export const AddGallery = () => {
    const [form, setForm] = useState({
        imageUrl1: '',
    });
    const [error, setError] = useState({});
    const [file1, setFile1] = useState(null);
    const [isSubmit, setIsSubmit] = useState(false);
    const navigate = useNavigate();
    const [uploadedImageUrl, setUploadedImageUrl] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const uploadFile = async (file, imageUrlKey) => {
            const storageRef = ref(storage, file.name);
            const uploadTask = uploadBytesResumable(storageRef, file);

            try {
                await uploadTask;

                const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

                setForm((prevForm) => ({ ...prevForm, [imageUrlKey]: downloadURL }));
                setUploadedImageUrl(downloadURL); // Set the uploaded image URL
            } catch (error) {
                console.error('Error uploading file: ', error);
            }
        };

        if (file1) {
            uploadFile(file1, 'imageUrl1');
        }
    }, [file1]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const isEmptyField = Object.values(form).some((value) => value === '');
        if (isEmptyField) {
            setError({ submit: 'All Fields are required' });
            alert('All fields are required');
            return;
        }

        setIsSubmit(true);
        setIsLoading(true);

        try {
            const docRef = await addDoc(collection(db, 'Gallery'), {
                ...form,
                timestamp: serverTimestamp(),
            });

            setModalMessage('Data is stored in the database');
            setIsModalOpen(true);
            setTimeout(() => {
                setIsModalOpen(false);
                setIsLoading(false);
                navigate('/');
            }, 3000); // Close modal and navigate after 3 seconds
        } catch (error) {
            console.error('Error adding document: ', error);
            setModalMessage('Error: Failed to add data to the database');
            setIsModalOpen(true);
            setIsLoading(false);
        }
    };

    return (
        <>
            <main className="mt-20 ml-6">
                <h1 className="w-fit mx-auto text-blue-700">Add Gallery</h1>
                <span className="flex w-[120px] h-[2px] bg-[#e1e1e1] mx-auto mb-4">
                    <em className="w-[60px] h-[2px] bg-blue-700 mx-auto" />
                </span>
                <form className="w-[60%] h-auto p-10 bg-slate-50 shadow-lg mx-auto">
                    <div className="flex flex-wrap gap-4">
                        {/* Custom-styled file input */}
                        <label htmlFor="fileInput" className="cursor-pointer flex items-center justify-center border border-dashed border-[#1475cf] min-h-[120px] min-w-[120px] max-h-[120px] max-w-[120px]">
                            <input
                                type="file"
                                id="fileInput"
                                accept="image/*"
                                className="hidden"
                                onChange={(e) => setFile1(e.target.files[0])}
                            />
                            {/* Your custom icon or text for the "Choose File" box */}
                            <span className="text-[12px] text-center font-bold">(Choosefile)</span>
                        </label>

                        {/* Display uploaded image */}
                        {uploadedImageUrl && (
                            <img
                                src={uploadedImageUrl}
                                alt="Uploaded"
                                className="w-[120px] h-[120px] border border-blue-600 object-cover"
                            />
                        )}
                    </div>

                    <button
                        onClick={handleSubmit}
                        type="submit"
                        className="bg-blue-400 py-2 px-20 rounded text-black mt-5"
                    >
                        {isLoading ? <Loader /> : 'Create'}
                    </button>
                </form>

                {isModalOpen && (
                    <Modal onClose={() => setIsModalOpen(false)}>
                        <p>{modalMessage}</p>
                    </Modal>
                )}
            </main>
        </>
    );
};
