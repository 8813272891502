import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import { collection, deleteDoc, doc, getDocs } from "firebase/firestore";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Buttons from "../../Common/Buttons";
import { FaRegEdit, FaRegEye } from "react-icons/fa";
import { DeleteModal } from "../../Common/DeleteModal";

const iconClass = "text-xl";
const tableData = "border-b border-[#eee] py-3 px-4 ";
const heading = "py-4 text-xl font-bold text-gray-800 pl-4 p-10   bg-blue-100";

export const ViewNews = () => {
  const [NewsData, setNewsData] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchNewsData = async () => {
      const NewsCollection = collection(db, "News");
      const snapshot = await getDocs(NewsCollection);
      const data = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setNewsData(data);
    };
    fetchNewsData();
  }, []);

  const handleDelete = (id) => {
    setSelectedItemId(id);
    setIsDeleteModalOpen(true);
  };

  const confirmDelete = async () => {
    try {
      const NewsRef = doc(db, "News", selectedItemId);
      await deleteDoc(NewsRef);
      setNewsData(NewsData.filter((item) => item.id !== selectedItemId));
      setIsDeleteModalOpen(false);
    } catch (error) {
      console.log("error deleting document", error);
    }
  };

  const handleEdit = (id) => {
    try {
      const foundNews = NewsData.find((news) => news.id === id);
      navigate(`/editblog/${id}`, { state: { editData: foundNews } });
    } catch (error) {
      console.log("error navigating to edit page", error);
    }
  };

  return (
    <div className="ml-40  px-5 pt-6 pb-2.5 ">
      <div className="flex justify-end mb-10">
        <Buttons type="button" variant="secondary" to="/addnews">
          Add
        </Buttons>
      </div>
      <div className="rounded-sm  bg-white  shadow-default  dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
        <div className="overflow-x-auto  border  border-gray-100">
          <table className="w-full table-auto border-collapse  ">
            <thead>
              <tr className="bg-gray-200 text-left ">
                <th className={heading}>Image</th>
                <th className={heading}>Image2</th>
                <th className={heading}>Username</th>
                <th className={heading}>Category</th>
                <th className={heading}>date</th>
                <th className={heading}>Title</th>
                <th className={heading}>Description</th>
                <th className={heading}>Action</th>
              </tr>
            </thead>
            <tbody>
              {NewsData && NewsData.length > 0
                ? NewsData.map((NewsData, key) => (
                    <tr key={key} className={key % 2 === 0 ? "bg-gray-100 " : ""}>
                      <td className={tableData}>
                        <img
                          alt="founderimage"
                          src={NewsData.imageUrl1}
                          className="h-20 w-20  object-cover border border-gray-100"
                        />
                      </td>
                      <td className={tableData}>
                        <img
                          alt="founderimage"
                          src={NewsData.imageUrl2}
                          className="h-20 w-20  object-cover border border-gray-100"
                        />
                      </td>
                      <td className={tableData}>
                        <p className="text-black text-xl">{NewsData.username}</p>
                      </td>
                      <td className={tableData}>
                        <p className="text-black text-xl">{NewsData.category}</p>
                      </td>
                      <td className={tableData}>
                        <p className="text-black text-xl">{NewsData.date}</p>
                      </td>
                      <td className={tableData}>
                        <p className="text-black text-xl">{NewsData.title.slice(0, 10)}</p>
                      </td>
                      <td className={tableData}>
                        <p className="text-black text-xl">{NewsData.description.slice(0, 30)}.</p>
                      </td>
                      <td className={tableData}>
                        <div className="flex items-center space-x-3.5">
                          <span className="hover:text-primary cursor-pointer">
                            <FaRegEdit className={iconClass} onClick={() => handleEdit(NewsData.id)} />
                          </span>
                          <span
                            className="hover:text-primary cursor-pointer"
                            onClick={() => handleDelete(NewsData.id)}
                          >
                            <MdOutlineDeleteOutline className={iconClass}/>
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))
                : (
                    <tr>
                      <td colSpan="8" className="text-center text-[20px] py-5">
                        No Data Found
                      </td>
                    </tr>
                  )}
            </tbody>
          </table>
        </div>
      </div>

      {/* Add the DeleteModal component here */}
      <DeleteModal
        isModalOpen={isDeleteModalOpen}
        closeModal={() => setIsDeleteModalOpen(false)}
        titleName={`News item ${selectedItemId}`}  // Adjust the title or details accordingly
        handleDelete={confirmDelete}
      />
    </div>
  );
};
