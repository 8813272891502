import React, { useState, useEffect } from 'react';
import { doc, getDoc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { db, storage } from '../../firebase'; // Replace with your Firebase configuration
import { News } from '../../Common/Dashboard_index'; // Replace with your News component
import { useNavigate, useParams } from 'react-router-dom';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

export const EditBlog = () => {
  const { id } = useParams();
  const [form, setForm] = useState({
    username: '',
    title: '',
    category: '',
    date: '',
    description: '',
    imageUrl1: '',
    imageUrl2: '',
  });
  const [file1, setFile1] = useState(null);
  const [file2, setFile2] = useState(null);
  const [progress, setProgress] = useState(null);
  const [error, setError] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const blogDoc = await getDoc(doc(db, 'News', id));
        if (blogDoc.exists()) {
          setForm(blogDoc.data());
        } else {
          console.error('Blog not found');
        }
      } catch (error) {
        console.error('Error fetching blog data:', error);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    const uploadFile = async (file, imageUrlKey) => {
      const storageRef = ref(storage, file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);

      try {
        await uploadTask;

        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

        setForm((prevForm) => ({ ...prevForm, [imageUrlKey]: downloadURL }));
      } catch (error) {
        console.error('Error uploading file: ', error);
      }
    };

    if (file1) {
      uploadFile(file1, 'imageUrl1');
    }
  }, [file1]);

  useEffect(() => {
    const uploadFile = async (file, imageUrlKey) => {
      const storageRef = ref(storage, file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);

      try {
        await uploadTask;

        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

        setForm((prevForm) => ({ ...prevForm, [imageUrlKey]: downloadURL }));
      } catch (error) {
        console.error('Error uploading file: ', error);
      }
    };

    if (file2) {
      uploadFile(file2, 'imageUrl2');
    }
  }, [file2]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setForm((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isEmptyField = Object.values(form).some((value) => value === '');
    if (isEmptyField) {
      setError({ submit: 'All fields are required' });
      alert('All fields are required');
      return;
    }

    setIsSubmitting(true);

    try {
      const blogRef = doc(db, 'News', id);
      await updateDoc(blogRef, {
        ...form,
        timestamp: serverTimestamp(),
      });

      navigate('/viewnews');
    } catch (error) {
      console.error('Error updating document:', error);
      setError({ submit: 'An error occurred. Please try again.' });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <main className="mt-20 ml-20 ">
      <h1 className="w-fit mx-auto text-blue-700">Edit Blog</h1>
      <span className="flex w-[120px] h-[2px] bg-[#e1e1e1]  mx-auto mb-4">
        <em className="w-[60px] h-[2px] bg-blue-700 mx-auto" />
      </span>
      <form className="w-[80%] h-auto  p-10 bg-slate-50 shadow-lg mx-auto">
        <div className="flex flex-wrap  gap-10">
          <div className="flex flex-wrap  w-96 justify-between gap-1">
            <label htmlFor="fileInput1" className="cursor-pointer flex items-center justify-center border border-dashed border-[#1475cf] min-h-[120px] min-w-[120px] max-h-[120px] ">
              <input
                type="file"
                id="fileInput1"
                accept="image/*"
                className="hidden"
                onChange={(e) => setFile1(e.target.files[0])}
              />
              <span className="text-[12px] text-center font-bold">(Choose file)</span>
            </label>
            {form.imageUrl1 && (
              <img
                src={form.imageUrl1}
                alt="Uploaded"
                className="w-[120px] h-[120px] border border-blue-600 object-cover"
              />
            )}
          </div>
          <div className="flex flex-wrap w-96 justify-between gap-1">
            <label htmlFor="fileInput2" className="cursor-pointer flex items-center justify-center border border-dashed border-[#1475cf] min-h-[120px] min-w-[120px] max-h-[120px] ">
              <input
                type="file"
                id="fileInput2"
                accept="image/*"
                className="hidden"
                onChange={(e) => setFile2(e.target.files[0])}
              />
              <span className="text-[12px] text-center font-bold">(Choose file)</span>
            </label>
            {form.imageUrl2 && (
              <img
                src={form.imageUrl2}
                alt="Uploaded"
                className="w-[120px] h-[120px] border border-blue-600 object-cover"
              />
            )}
          </div>
        </div>

        <div className="flex flex-wrap py-4 mt-10">
          {News.map((item) => (
            <div key={item.name} className="w-full md:w-1/3 mt-4 lg:w-1/3">
              <label className="block text-sm font-medium text-gray-700">{item.placeH}</label>
              <input
                onChange={handleChange}
                value={form[item.name]}
                type={item.type}
                name={item.name}
                className="mt-1 p-3 w-80 bg-gray-100 rounded"
                placeholder={item.placeH}
                required
              />
            </div>
          ))}
          <div className="w-full md:w-1/2 mt-4 lg:w-1/3">
            <label className="block text-sm font-medium text-gray-700">Description</label>
            <textarea
              id="description"
              name="description"
              rows="4"
              cols="50"
              placeholder="Write description"
              value={form.description}
              onChange={(e) => handleChange(e)}
              className="mt-1 p-3 w-full bg-gray-100 rounded"
            />
          </div>
        </div>

        <button
          onClick={handleSubmit}
          type="submit"
          className="bg-blue-400 py-2 px-20 rounded text-black mt-5"
        >
          Update
        </button>
      </form>
    </main>
  );
};
