import { collection, getDocs } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import CountUp from 'react-countup';
import { db } from '../../firebase';

const CounterBlog = () => {
    const [CounterData, setCounterData] = useState([]);

    useEffect(() => {
        const fetchCounterData = async () => {
            const CounterCollection = collection(db, "Counter");
            const snapshot = await getDocs(CounterCollection);
            const data = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            setCounterData(data);
        };

        fetchCounterData();
    }, []);

    return (
        <div className="row">
            {CounterData.map((data, index) => (
                <div className="col-lg-3 col-6 m-b30" key={index}>
                    <div className="counter-style-1 text-center">
                        <span className="counter counter-num">
                            <CountUp
                                end={data.number}
                                separator=","
                                duration="3"
                            />
                        </span>
                        <p className="counter-text">{data.title}</p>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default CounterBlog;
