import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { auth, db } from "../firebase";
import { Link } from "react-router-dom";
import Silicon from "../assets/images/silicontech-removebg-preview.png";
import Picture from "../assets/images/project/pic3.jpg";
import Quba from "../assets/images/Quba - Copy.png";
import { collection, getDocs } from "firebase/firestore";
const Login = () => {
  const [isLogin, setIsLogin] = useState(true);
  const navigate = useNavigate();
  // useEffect(()=>{
  //   onAuthStateChanged(auth,(data)=>{
  //     console.log(data);
  //   })
  // })
  useEffect(() => {
    const checkAuthStatus = async () => {
      const user = auth.currentUser;

      if (user) {
        const accessToken = user.accessToken;
        console.log("Access Token:", accessToken);
      } else {
        console.log("User not logged in.");
      }
    };

    checkAuthStatus();
  }, []);

  const LoginForm = () => {
    useEffect(() => {
      const checkAuthStatus = async () => {
        const user = auth.currentUser;

        if (user) {
          const accessToken = user.accessToken;
          console.log("Access Token:", accessToken);
        } else {
          console.log("User not logged in.");
        }
      };

      checkAuthStatus();
    }, []);
    const [ErrMsg, setErrMsg] = useState("");
    const [data, setData] = useState();
    const [submitButtonDisabled, setsubmitButtonDisabled] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [values, setValues] = useState({
      email: "",
      password: "",
    });

    useEffect(() => {
      const fetchadminDetail = async () => {
        const adminDetails = collection(db, "admindetail");
        const snapshot = await getDocs(adminDetails);
        const data = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        console.log(data[0]);
        setData(data[0]);
      };

      fetchadminDetail();
    }, []);

    console.log(data)
    console.log(values)

    const handleSubmit = () => {
      if (!values.email || !values.password) {
        setErrMsg("Fill all Fields");
        return;
      }

      const match =
        data.email === values.email && data.password === values.password;
      console.log(match);
      if (match) {
        localStorage.setItem("accessToken",data.id);
        navigate("/dashboard");
      } else {
        setErrMsg("Invalid erro message");
      }
    };

    return (
      <div className=" flex flex-col w-full h-full  justify-center border-red-500 items-center max-w-screen transition duration-1000 ease-out">
        <div className="  rounded p-20 ">
          <img
            src={Quba}
            alt="logo"
            className="w-30 h-20 object-cover mx-auto"
          />

          <h3 className="text-3xl text-center font-semibold text-blue-400 pt-2">
            Sign In!
          </h3>

          {ErrMsg && <h1 className="font-bold">{ErrMsg}</h1>}
          {successMessage && <h1 className="font-bold">{successMessage}</h1>}

          {/* Inputs */}

          <div className="flex flex-col items-center justify-center p-4">
            <input
              type="email"
              onChange={(event) =>
                setValues((prev) => ({
                  ...prev,
                  email: event.target.value,
                }))
              }
              className=" px-3 py-2 w-5/6 md:w-full border-[1px] border-blue-400 my-3 shadow appearance-none focus:border-pink-400 focus:outline-none focus:ring-0 text-xl "
              placeholder="Email"
            />

            <input
              type="password"
              className="px-3 py-2 w-5/6 md:w-full border-[1px] border-blue-400 my-3 shadow appearance-none focus:border-pink-400 focus:outline-none focus:ring-0 text-xl"
              placeholder="Password"
              onChange={(event) =>
                setValues((prev) => ({
                  ...prev,
                  password: event.target.value,
                }))
              }
            />
            <button
              className="px-3 py-2 w-5/6 md:w-full border-[1px] text-xl text-white font-bold bg-[#5CB3FF] my-3 shadow appearance-none transition duration-200 ease-in"
              onClick={handleSubmit}
              disabled={submitButtonDisabled}
            >
              Sign In
            </button>
          </div>

       
         
          <div className="w-full text-center flex items-center justify-center">
            <Link
              to="https://silicontechnepal.com.np/"
              className="flex items-center"
            >
              <span className="mr-2">Powered by:</span>
              <figure className="object-cover ">
                <img
                  src={Silicon}
                  alt="logo"
                  className="w-40 h-20 object-cover"
                />
              </figure>
            </Link>
          </div>
        </div>
      </div>
    );
  };


  return (
    <>
      <div className="h-[100vh]   ">
        <main className="flex  flex-row h-full">
          <div className="hidden md:block w-full basis-[50%] h-full ">
            <img
              src={Picture}
              alt="login"
              className="h-full w-full object-cover"
            />
          </div>

          <div className="basis:[100%] md:basis-[50%] h-full overflow-y-hidden overflow-x-hidden  ">
            {isLogin ? <LoginForm /> : null }
          </div>
        </main>
      </div>
    </>
  );
};

export default Login;
