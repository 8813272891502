import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
//import { LazyLoadImage } from 'react-lazy-load-image-component';

// Import Swiper styles
import "swiper/css";

//Images
import pic1 from "./../../assets/images/project/pic1.jpg";
import pic2 from "./../../assets/images/project/pic2.jpg";
import pic3 from "./../../assets/images/project/pic3.jpg";
import avt1 from "./../../assets/images/avatar/avatar1.jpg";
import avt2 from "./../../assets/images/avatar/avatar2.jpg";
import avt3 from "./../../assets/images/avatar/avatar3.jpg";

// import Swiper core and required modules
import { Autoplay } from "swiper/modules";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";

//SwiperCore.use([EffectCoverflow,Pagination]);

const dataBlog = [
  { image: pic1, image2: avt1 },
  { image: pic2, image2: avt2 },
  { image: pic3, image2: avt3 },
];

const RecentProjectsSlider = () => {
  const [ProjectData, setProjectData] = useState([]);
  const [currentData, setCurrentData] = useState();

  useEffect(() => {
    const fetchProject = async () => {
      const projectCOllection = collection(db, "Project");
      const snapshot = await getDocs(projectCOllection);
      const projectData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setProjectData(projectData);
    };
    fetchProject();
    console.log(fetchProject);
  }, []);
  console.log(currentData)
  return (
    <>
      <Swiper
        className="recent-blog p-b5"
        speed={1500}
        //parallax= {true}
        slidesPerView={3}
        spaceBetween={30}
        loop={true}
        autoplay={{
          delay: 3000,
        }}
        modules={[Autoplay]}
        breakpoints={{
          1200: {
            slidesPerView: 3,
          },
          992: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 2,
          },
          575: {
            slidesPerView: 2,
          },
          320: {
            slidesPerView: 1,
          },
        }}
      >
        {ProjectData.map((project, i) => (
          <SwiperSlide key={i}>
            <div
              onClick={() => {
                setCurrentData(project);
              }}
              className="dz-card style-2 w-full lg:w-[25rem] sm:w-[25rem]  border border-gray-100 shadow  shadow-gray-200 wow fadeInUp text-lg" data-wow-delay="0.2s"


            >
              <div className="dz-media  ">
                <Link to={`/fundraiser-detail/${project.id}`}>
                  <img
                    src={project.imageUrl1}
                    alt=""
                    className="lg:w-[25rem]  sm:w-[19rem] md:w-[23rem] h-[14rem] lg:h-[20rem] object-cover shadow-md"
                  />

                </Link>
              </div>
              <div className="p-3">
                <h5 className="font-bold truncate h-6">
                  <Link to={`/fundraiser-detail/${project.id}`}>
                    {project.title}
                  </Link>
                </h5>

              </div>
            </div>
          </SwiperSlide>
        ))}

      </Swiper>
    </>
  );
};

export default RecentProjectsSlider;
