import React from "react";
import { Route, Routes, Outlet } from "react-router-dom";

import ScrollToTop from "./../layouts/ScrollToTop";
import Footer from "./../layouts/Footer";
import Home from "./Home";

import AboutUs from "./AboutUs";
import BecomeVolunteer from "./BecomeVolunteer";
import Certificates from "./Certificates";
import HowItWorks from "./HowItWorks";
import Mission from "./Mission";
import TermsCondition from "./TermsCondition";
import Error from "./Error";
import UnderMaintenance from "./UnderMaintenance";
import ComingSoon from "./ComingSoon";
import BrowseFundraiser from "./BrowseFundraiser";
import BecomeFundraiser from "./BecomeFundraiser";
import FundraiserDetail from "./FundraiserDetail";
import Project from "./Project";
import ProjectCategories from "./ProjectCategories";
import ProjectSidebar from "./ProjectSidebar";
import ProjectStory from "./ProjectStory";
import Blog from "./Blog";
import BlogGrid from "./BlogGrid";
import BlogList from "./BlogList";
import BlogDetails from "./BlogDetails";
import ContactUs from "./ContactUs";

import { Dashboard } from "./Dashboard";

import { ViewContact } from "../components/Dashboard/ViewContact";
import { AddFounder } from "../components/Dashboard/AddFounder";
import { ViewFounder } from "../components/Dashboard/ViewFounder";
import { AddTestimonial } from "../components/Dashboard/AddTestimonial";
import EditFounder from "../components/Dashboard/EditFounder";
import { AddNews } from "../components/Dashboard/AddNews";
import { AddCategories } from "../components/Dashboard/AddCtegories";
import { AddCounterData } from "../components/Dashboard/AddCounterData";
import { AddProject } from "../components/Dashboard/AddProject";
import { AddPartnership } from "../components/Dashboard/AddPartnership";
import { ViewPartnership } from "../components/Dashboard/ViewPartnership";
import { ViewTestimonial } from "../components/Dashboard/ViewTestimonial";
import { ViewCategory } from "../components/Dashboard/ViewCategory";
import { ViewCounter } from "../components/Dashboard/ViewCounter";
import { ViewNews } from "../components/Dashboard/ViewNews";
import Headers from "../components/Home/Header";
import { SuccessStorys } from "../components/Dashboard/AddSuccessstories";
import { ViewProject } from "../components/Dashboard/ViewProject";
import { ViewSuccessStory } from "../components/Dashboard/ViewSuccessStory";
import { AddCertificate } from "../components/Dashboard/AddCertificate";
import { ViewCertificate } from "../components/Dashboard/ViewCertificate";
import { Gallery } from "./Gallery";
import { AddGallery } from "../components/Dashboard/AddGallery";
import { ViewGallery } from "../components/Dashboard/ViewGallery";
import Login from "./Login";
import PrivateRoutes from "../Common/PrivateRoute";
import { EditCertificate } from "../components/Dashboard/EditCertificate";
import { EditCounter } from "../components/Dashboard/EditCounter";
import { EditCategory } from "../components/Dashboard/EditCategory";
import { EditPartnership } from "../components/Dashboard/EditPartnership";
import { EditProject } from "../components/Dashboard/EditProject";
import { EditBlog } from "../components/Dashboard/EditBlog";
import { EditSuccessStories } from "../components/Dashboard/EditSuccessStories";
import { EditGallery } from "../components/Dashboard/EditGallery";
import { EditTestimonial } from "../components/Dashboard/EditTestimonial";
import { AddHomeBanner } from "../components/Dashboard/AddHomeBanner";
import { ViewHomeBanner } from "../components/Dashboard/ViewHomeBanner";
import { EditBanner } from "../components/Dashboard/EditHomeBanner";

function Index() {
  return (
    <>
      <Routes>
        <Route path="/login" exact element={<Login />} />
        <Route path="/error-404" exact element={<Error />} />
        <Route path="/under-maintenance" exact element={<UnderMaintenance />} />
        <Route path="/coming-soon" exact element={<ComingSoon />} />
        {/* <Route
          path="/dashboard"
          element={<PrivateRoutes element={<Dashboard />} />}
        /> */}

        {/* <Route path="/dashboard" exact element={<Dashboard />} /> */}
        <Route element={<PrivateRoutes />}>
          <Route exact element={<Dashboard />}>
            <Route path="/editfounder/:id" exact element={<EditFounder />} />
            <Route path="/viewContact" exact element={<ViewContact />} />
            <Route path="/addnews" exact element={<AddNews />} />
            <Route path="/addhomebanner" exact element={<AddHomeBanner />} />
            <Route path="/editbanner/:id" element={<EditBanner />} />
            <Route path="/viewhomebanner" exact element={<ViewHomeBanner />} />
            <Route path="/editblog" exact element={<EditBlog />} />
            <Route path="/addcategory" exact element={<AddCategories />} />
            <Route path="/addfounder" exact element={<AddFounder />} />
            <Route path="/dashboard" exact element={<ViewFounder />} />
            <Route
              path="/viewsuccessstory"
              exact
              element={<ViewSuccessStory />}
            />
            <Route path="/addsuccessstory" exact element={<SuccessStorys />} />
            <Route
              path="/editsuccessstory/:id"
              exact
              element={<EditSuccessStories />}
            />
            <Route path="/viewcategory" exact element={<ViewCategory />} />
            <Route path="/editcategory/:id" exact element={<EditCategory />} />
            <Route path="/viewnews" exact element={<ViewNews />} />
            <Route path="/editblog/:id" exact element={<EditBlog />} />
            <Route path="/viewcounter" exact element={<ViewCounter />} />
            <Route path="/editcounter/:id" exact element={<EditCounter />} />
            <Route
              path="/viewtestimonial"
              exact
              element={<ViewTestimonial />}
            />
            <Route
              path="/viewpartnership"
              exact
              element={<ViewPartnership />}
            />
            <Route
              path="/editpartnership/:id"
              exact
              element={<EditPartnership />}
            />
            <Route path="/addpartnership" exact element={<AddPartnership />} />
            <Route path="/viewfounder" exact element={<ViewFounder />} />
            <Route path="/addgallery" exact element={<AddGallery />} />
            <Route path="/editgallery/:id" exact element={<EditGallery />} />
            <Route path="/viewgallery" exact element={<ViewGallery />} />
            <Route path="/addcertificate" exact element={<AddCertificate />} />
            <Route
              path="/viewcertificate"
              exact
              element={<ViewCertificate />}
            />
            <Route
              path="/editcertificate/:id"
              exact
              element={<EditCertificate />}
            />
            <Route path="/edit/:id" exact element={<AddFounder />} />
            <Route path="/addcounter" exact element={<AddCounterData />} />
            <Route path="/viewproject" exact element={<ViewProject />} />
            <Route path="/editproject/:id" exact element={<EditProject />} />
            <Route path="/addproject" exact element={<AddProject />} />
            <Route path="/addtestimonial" exact element={<AddTestimonial />} />
            <Route
              path="/edittestimonial/:id"
              exact
              element={<EditTestimonial />}
            />
          </Route>
        </Route>

        <Route element={<MainLayout />}>
          <Route path="/" exact element={<Home />} />
          <Route path="/about-us" exact element={<AboutUs />} />

          <Route
            path="/become-a-volunteer"
            exact
            element={<BecomeVolunteer />}
          />
          <Route path="/certificates" exact element={<Certificates />} />
          <Route path="/how-it-works" exact element={<HowItWorks />} />
          <Route path="/mission" exact element={<Mission />} />
          <Route
            path="/terms-and-condition"
            exact
            element={<TermsCondition />}
          />
          <Route
            path="/browse-fundraiser"
            exact
            element={<BrowseFundraiser />}
          />
          <Route
            path="/become-a-fundraiser"
            exact
            element={<BecomeFundraiser />}
          />
          <Route path="gallery" exact element={<Gallery />} />
          <Route
            path="/fundraiser-detail/:id"
            exact
            element={<FundraiserDetail />}
          />

          <Route path="/project" exact element={<Project />} />
          <Route
            path="/project-categories"
            exact
            element={<ProjectCategories />}
          />
          <Route path="/project-sidebar" exact element={<ProjectSidebar />} />
          <Route path="/project-story" exact element={<ProjectStory />} />
          <Route path="/blog" exact element={<Blog />} />
          <Route path="/blog-grid" exact element={<BlogGrid />} />
          <Route path="/blog-list" exact element={<BlogList />} />
          <Route path="/blog-details/:id" exact element={<BlogDetails />} />
          <Route path="/contact-us" exact element={<ContactUs />} />
        </Route>
      </Routes>

      <ScrollToTop />
    </>
  );
}

function MainLayout() {
  return (
    <div className="page-wraper ">
      <Headers />
      <Outlet />

      <Footer />
    </div>
  );
}
export default Index;
