import React, { useEffect, useState } from 'react';
import { RecentProject } from '../../Common/Dashboard_index';
import { useNavigate, useParams } from 'react-router-dom';
import { doc, getDoc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { db, storage } from '../../firebase';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';

export const EditProject = () => {
  const [error, setError] = useState({});
  const [file1, setFile1] = useState(null);
  const [file2, setFile2] = useState(null);
  const [uploadedImageUrl, setUploadedImageUrl] = useState('');
  const [uploadedImageUrl1, setUploadedImageUrl2] = useState('');
  const navigate = useNavigate();
  const { id } = useParams();
  const [isSubmit, setIsSubmit] = useState(false);
  const [form, setForm] = useState({
    author: '',
    imageUrl1: '',
    imageUrl2: '',
    title: '',
    description: '',
    category: '',
    no_campaign: '',
    Address: '',
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const blogDoc = await getDoc(doc(db, 'Project', id));
        if (blogDoc.exists()) {
          setForm(blogDoc.data());
        } else {
          console.error('Blog not found');
        }
      } catch (error) {
        console.error('Error fetching blog data:', error);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    const fetchImageUrls = async () => {
      try {
        const storageRef1 = ref(storage, form.imageUrl1);
        const downloadURL1 = await getDownloadURL(storageRef1);
        setUploadedImageUrl(downloadURL1);

        const storageRef2 = ref(storage, form.imageUrl2);
        const downloadURL2 = await getDownloadURL(storageRef2);
        setUploadedImageUrl2(downloadURL2);
      } catch (error) {
        console.error('Error fetching image URLs: ', error);
      }
    };

    fetchImageUrls();
  }, [form.imageUrl1, form.imageUrl2]);

  useEffect(() => {
    const uploadFile = async (file, imageUrlKey) => {
      const storageRef = ref(storage, file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);

      try {
        await uploadTask;

        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

        setForm((prevForm) => ({ ...prevForm, [imageUrlKey]: downloadURL }));
      } catch (error) {
        console.error('Error uploading file: ', error);
      }
    };

    if (file1) {
      uploadFile(file1, 'imageUrl1');
    }
  }, [file1]);

  useEffect(() => {
    const uploadFile = async (file, imageUrlKey) => {
      const storageRef = ref(storage, file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);

      try {
        await uploadTask;

        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

        setForm((prevForm) => ({ ...prevForm, [imageUrlKey]: downloadURL }));
      } catch (error) {
        console.error('Error uploading file: ', error);
      }
    };

    if (file2) {
      uploadFile(file2, 'imageUrl2');
    }
  }, [file2]);

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (files && files.length > 0) {
      const file = files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        setForm((prevData) => ({
          ...prevData,
          [name]: reader.result,
        }));
      };

      reader.readAsDataURL(file);
    } else {
      // Make sure to update the correct property for the description field
      if (name === 'description') {
        setForm((prevData) => ({
          ...prevData,
          description: value,
        }));
      } else {
        setForm((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isEmptyField = Object.values(form).some((value) => value === '');
    if (isEmptyField) {
      setError({ submit: 'All Fields are required' });
      alert('All fields are required');
      return;
    }

    setIsSubmit(true);

    try {
      // Assuming initialData.id is the document ID you want to update
      const counterRef = doc(db, 'Project', id);

      await updateDoc(counterRef, {
        ...form,
        timestamp: serverTimestamp(),
      });

      navigate('/viewproject');
    } catch (error) {
      console.error('Error updating document: ', error);
    }
  };

  const handleChangeFile = (e) => {
    const newFile = e.target.files[0];
    setFile1(newFile);

    // Update the uploaded image URL state to show the new image
    const objectURL = URL.createObjectURL(newFile);
    setUploadedImageUrl(objectURL);
  };

  const handleChangeFile2 = (e) => {
    const newFile = e.target.files[0];
    setFile2(newFile);

    // Update the uploaded image URL state to show the new image
    const objectURL = URL.createObjectURL(newFile);
    setUploadedImageUrl2(objectURL);
  };

  return (
    <main className="mt-20 ml-10  ">
      <h1 className="w-fit mx-auto text-blue-700">Edit Projects</h1>
      <span className="flex w-[120px] h-[2px] bg-[#e1e1e1]  mx-auto mb-4">
        <em className="w-[60px] h-[2px] bg-blue-700 mx-auto" />
      </span>
      <form className="w-[80%] h-auto  p-10 bg-slate-50 shadow-lg mx-auto">
        <div className=' flex gap-10'>
          <div className="flex flex-wrap  w-96 justify-between gap-1 ">
            <label htmlFor="fileInput" className="cursor-pointer flex items-center justify-center border border-dashed border-[#1475cf] min-h-[120px] min-w-[120px] max-h-[120px] ">
              <input
                type="file"
                id="fileInput"
                accept="image/*"
                className="hidden"
                onChange={handleChangeFile}
              />
              {/* Your custom icon or text for the "Choose File" box */}
              <span className="text-[12px] text-center font-bold">(Choosefile)</span>
            </label>
            {uploadedImageUrl && (
              <img
                src={uploadedImageUrl}
                alt="Uploaded"
                className="w-[120px] h-[120px] border border-blue-600 object-cover"
              />
            )}
          </div>
          <div className="flex flex-wrap  w-96 justify-between gap-1 ">
            <label htmlFor="fileInput2" className="cursor-pointer flex items-center justify-center border border-dashed border-[#1475cf] min-h-[120px] min-w-[120px] max-h-[120px] ">
              <input
                type="file"
                id="fileInput2"
                accept="image/*"
                className="hidden"
                onChange={handleChangeFile2}
              />
              {/* Your custom icon or text for the "Choose File" box */}
              <span className="text-[12px] text-center font-bold">(Choosefile)</span>
            </label>
            {uploadedImageUrl1 && (
              <img
                src={uploadedImageUrl1}
                alt="Uploaded"
                className="w-[120px] h-[120px] border border-blue-600 object-cover"
              />
            )}
          </div>
        </div>

        <div className="flex flex-wrap justify-between gap-4 mt-6">
          {RecentProject.map((item) => (
            <div key={item.name} className='flex  flex-col'>
            <label className="text-sm font-semibold text-black">{item.placeH}</label>
              <input
                onChange={handleChange}
                value={form[item.name]}
                type={item.type}
                name={item.name}
                className="p-3 my-3  w-96 bg-gray-100  rounded"
                placeholder={item.placeH}
                required
              />
            </div>
          ))}
          <textarea
            id="description"
            name="description"
            rows="4"
            cols="50"
            value={form.description}
            placeholder="write description"
            onChange={(e) => handleChange(e)}
          />
        </div>

        <button
          onClick={handleSubmit}
          type="submit"
          className="bg-blue-400 py-2 px-20 rounded text-black mt-5"
        >
          Create
        </button>
      </form>
    </main>
  );
};
