import React, { useEffect, useState } from 'react'
import PageBanner from '../layouts/PageBanner'

import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import bgImage from './../assets/images/banner/galary.jpg';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

export const Gallery = () => {
    const [gallery, setGallery] = useState([]);
    useEffect(() => {
        const fetchGallery = async () => {
            const GalleryCollection = collection(db, "Gallery");
            const snapshot = await getDocs(GalleryCollection);
            const Gallerydata = snapshot.docs.map(doc => doc.data());
            setGallery(Gallerydata)
        }
        fetchGallery();
    }, [])
    return (
        <div className="page-content bg-white  overflow-x-hidden">
            <div className='relative'>
  <img src={bgImage} alt="" className='w-full h-[20rem] md:h-[32rem] object-cover' />
  {/* Background color overlay */}
  <div className='absolute inset-0 bg-[#6b696a] bg-opacity-50'></div>
  {/* Content */}
  <span className='absolute md:bottom-[12rem] bottom-[8rem] flex bg-transparent w-full md:h-20 p-2 rounded text-center  text-black font-extrabold text-2xl md:text-6xl justify-center '>
  <div className="dz-bnr-inr-entry  w-full">
                    
                        <nav aria-label="breadcrumb" className="breadcrumb-row">
                            <ul className="breadcrumb">
                              
                                <li className="breadcrumb-item active">Gallery</li>
                            </ul>
                        </nav>
                    </div>
  </span>
</div>
            <div className="content-inner">
                <div className="container  flex flex-row flex-wrap gap-3 sm:gap-6  mx-10 md:gap-10  ">

                    {gallery && gallery.map((item, index) => (
                        <div data-exthumbimage={item.image} data-src={item.image} title="Image" className="flex sm:gap-10  mx-[1px] sm:mx-[10px]" key={index}>
                            <figure className='flex-col '>
                                <img src={item.imageUrl1} alt="" className='lg:h-72 lg:w-96 sm:h-40 sm:w-40 shadow-sm w-40   h-40 object-cover  ' />
                            </figure>
                        </div>
                    ))}

                </div>
            </div>
        </div>
    )
}
