import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper/modules";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";

const HomeSlider = () => {
  const [banner, setBanner] = useState([]);

  useEffect(() => {
    const fetchBanner = async () => {
      const BannerCOllection = collection(db, "HomeBanner");
      const snapshot = await getDocs(BannerCOllection);
      const BannerImage = snapshot.docs.map((doc) => doc.data());
      setBanner(BannerImage);
    };
    fetchBanner();
  }, []);

  return (
    <>
      {banner && banner?.length > 0 ? (
        <Swiper
          className="team-slider"
          speed={1000}
          slidesPerView={1}
          spaceBetween={0}
          loop={true}
          autoplay={{
            delay: 1000,
          }}
          modules={[Autoplay]}
          breakpoints={{
            1200: { slidesPerView: 1 },
            992: { slidesPerView: 1 },
            768: { slidesPerView: 1 },
            575: { slidesPerView: 1 },
            420: { slidesPerView: 1 },
            320: { slidesPerView: 1 },
          }}
        >
          {banner?.map((image, i) => (
            <div key={i}>
              <SwiperSlide>
                <div className="relative w-full">
                  <img
                    src={image?.imageUrl1}
                    alt=""
                    className="w-full md:h-[40rem] h-[24rem] object-cover relative"
                  />

                  <div className="absolute hidden md:block md:top-2/4 top-1/4  w-[40%] left-24 text-gray-100 ">
                    <h2 className="text-gray-800 font-bold text-3xl  ">
                      {image.Title}
                    </h2>
                    <p className="text-gray-600 text-xl ">
                      {image.description}
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            </div>
          ))}
        </Swiper>
      ) : (
        <img
          src="https://img.freepik.com/photos-gratuite/groupe-soutien-aux-femmes_53876-94863.jpg?w=826&t=st=1712833429~exp=1712834029~hmac=6afb6bbc672a447c0da62385f908eb0a23c02fa96a4dfe93571a814f868e81e9"
          className="w-full md:h-[40rem] h-[24rem] object-cover relative"
          alt="category"
        />
      )}
    </>
  );
};

export default HomeSlider;
