import React from 'react';
import {Link} from 'react-router-dom';

import FooterCommonData from './FooterCommonData';

import logo from './../assets/images/logo-white.png';
import shape7 from './../assets/images/pattern/shape7.png';
import shape8 from './../assets/images/pattern/shape8.png';
import shape9 from './../assets/images/pattern/shape9.png';
import Silicon from "../assets/images/silicontech-removebg-preview.png";

const Footer = () => {
    return (
        <>
            <footer className="site-footer style-1 sm:mt-10 admin-header md:mt-20 " id="footer">
                <div className="footer-top">
                    <div className="container">
                        <div className="">
                            <FooterCommonData logoImage={logo} />
                        </div>
                    </div>
                    <img src={shape7} className="shape-1 move-2" alt="shape" />
                    <img src={shape8} className="shape-2 move-2" alt="shape" />
                    <img src={shape9} className="shape-3 move-2" alt="shape" />
                    
                </div>               
                {/* <div className="footer-bottom">
                    <div className="container">
                        <div className="text-center"> 
                            <span className="">Copyright © 2023 . All rights reserved.</span> 
                        </div>
                    </div>
                </div> */}
                <div className="w-full text-center flex items-center justify-center">
        <Link
          to="https://silicontechnepal.com.np/"
          className="flex items-center"
        >
          <span className="mr-2">Powered by:</span>
          <figure className="object-cover ">
            <img src={Silicon} alt="logo" className="w-40 h-20 object-cover" />
          </figure>
        </Link>
      </div>
            </footer>  
        </>
    );
};


export default Footer;