import React from "react";
import { Link } from "react-router-dom";
import Silicon from "../assets/images/silicontech-removebg-preview.png";

const FooterCommonData = ({ logoImage, iconStyle }) => {
  return (
    <>
      <div className="flex flex-col md:gap-10 md:flex-row">
        {/* First Column */}
        <div className="lg:w-1/2  md:w-full ">
          <div className="">
            <div className="footer-logo w-40 h-20  logo-white">
              <Link to={"/"}>
                <img src={logoImage} alt="" className="" />
              </Link>
            </div>
            <p className="text-justify">
              Quba is a Crowdfunding & Charity Website by DexignZone lorem ipsum
              dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et
            </p>
            {/* <div className="dz-social-icon style-1">
              <ul>
                <li>
                  <a
                    target="_blank"
                    className="fab fa-facebook-f"
                    rel="noreferrer"
                    href="https://www.facebook.com/"
                  ></a>
                </li>
                <li>
                  <a
                    target="_blank"
                    className="fab fa-instagram"
                    rel="noreferrer"
                    href="https://www.instagram.com/"
                  /> 
                </li>
                <li>
                  <a
                    target="_blank"
                    className="fab fa-twitter"
                    rel="noreferrer"
                    href="https://twitter.com/"
                  ></a>
                </li>
                <li>
                  <a
                    target="_blank"
                    className="fab fa-youtube"
                    rel="noreferrer"
                    href="https://youtube.com/"
                  ></a>
                </li>
              </ul>
            </div> */}
          </div>
        </div>

        {/* Second Column */}
        <div className="lg:w-1/6  md:w-full">
          <div className="widget_services">
            <h5 className="footer-title">Resources</h5>
            <ul>
              <li>
                <Link to={"/project-story"}>Project Story</Link>
              </li>
              <li>
                <Link to={"/mission"}>Mission</Link>
              </li>
              <li>
                <Link to={"/certificates"}>Certificates</Link>
              </li>
              <li>
                <Link to={"/terms-and-condition"}>Terms And Condition</Link>
              </li>
            </ul>
          </div>
        </div>

        {/* Third Column */}
        <div className="lg:w-1/6 md:w-full">
          <div className=" widget_services">
            <h5 className="footer-title">Company</h5>
            <ul>
              <li>
                <Link to={"/about-us"}>About Us</Link>
              </li>
              <li>
                <Link to={"/project"}>Project</Link>
              </li>
              <li>
                <Link to={"/contact-us"}>Contact Us</Link>
              </li>
              <li>
                <Link to={"/gallery"}>Gallery</Link>
              </li>
            </ul>
          </div>
        </div>

        {/* Fourth Column */}
        <div className="lg:w-1/6 md:w-full">
          <div className=" widget_getintuch">
            <h5 className="footer-title">Get in Touch with Us</h5>
            <ul>
              <li>
                {iconStyle ? (
                  <i className="fas fa-map-marker-alt  text-primary"></i>
                ) : (
                  <i className="fas fa-map-marker-alt"></i>
                )}
                <span>
                  832 Thompson Drive, San Fransisco CA 94107, United States
                </span>
              </li>
              <li>
                {iconStyle ? (
                  <i className="fa-solid fa-phone text-primary"></i>
                ) : (
                  <i className="fa-solid fa-phone"></i>
                )}
                <span>394-091-3312</span>
              </li>
              <li>
                {iconStyle ? (
                  <i className="fa fa-envelope text-primary"></i>
                ) : (
                  <i className="fa fa-envelope"></i>
                )}
                <span>support@gmail.com</span>
              </li>
            </ul>
          </div>
        </div>

        {/* Powered by Section */}
      </div>
   
    </>
  );
};

export default FooterCommonData;
