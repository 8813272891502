import React, { useState, useEffect } from "react";
import { storage, db } from "../../firebase";
import { userParams, useNavigate } from "react-router-dom";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { News } from "../../Common/Dashboard_index";
import Loader from '../../Common/Loader';
import Modal from '../../Common/Modal';

export const AddNews = () => {
  const [form, setform] = useState({
    username: "",
    title: "",
    category: "",
    date: "",
    description: "",
    imageUrl1: '',
    imageUrl2: '',
  });
  const [file1, setFile1] = useState(null);
  const [file2, setFile2] = useState(null);
  const [progress, setProgress] = useState(null);
  const [error, setError] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const uploadFile = (file, setFile, imageUrlKey) => {
      const storageRef = ref(storage, file.name);
      const uploadFile = uploadBytesResumable(storageRef, file);

      uploadFile.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(progress);
          switch (snapshot.state) {
            case 'paused':
              console.log('upload is paused');
              break;
            case 'running':
              console.log('upload is running');
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadFile.snapshot.ref).then((downloadURL) => {
            setform((prevForm) => ({ ...prevForm, [imageUrlKey]: downloadURL }));
          });
        }
      );
    };

    const uploadFiles = async () => {
      if (file1) {
        uploadFile(file1, setFile1, 'imageUrl1');
      }

      if (file2) {
        uploadFile(file2, setFile2, 'imageUrl2');
      }
    };

    uploadFiles();
  }, [file1, file2]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setform({ ...form, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isEmptyField = Object.values(form).some((value) => value === '');
    if (isEmptyField) {
      setError({ submit: 'All Fields are required' });
      alert('All fields are required');
      return;
    }

    setIsSubmit(true);
    setIsLoading(true);
    try {
      const docRef = await addDoc(collection(db, 'News'), {
        ...form,
        timestamp: serverTimestamp(),
      });

      setModalMessage('Data is stored in the database');
      setIsModalOpen(true);
      setTimeout(() => {
        setIsModalOpen(false);
        setIsLoading(false);
        navigate('/');
      }, 3000); // Close modal and navigate after 3 seconds
    } catch (error) {
      console.error('Error adding document: ', error);
      setModalMessage('Error: Failed to add data to the database');
      setIsModalOpen(true);
      setIsLoading(false);
    }
  };

  return (
    <>
      <main className="mt-20 ml-20 ">
        <h1 className="w-fit mx-auto text-blue-700">Add News</h1>
        <span className="flex w-[120px] h-[2px] bg-[#e1e1e1]  mx-auto mb-4">
          <em className="w-[60px] h-[2px] bg-blue-700 mx-auto" />
        </span>
        <form className="w-[80%] h-auto  p-10 bg-slate-50 shadow-lg mx-auto">
          <div className="flex flex-wrap justify-between gap-4">
            {News.map((item) => (
              <div key={item} className="flex flex-col">
              <label className="text-sm font-semibold text-black">{item.placeH}</label>
                <input
                  onChange={handleChange}
                  value={form[item.name]}
                  type={item.type}
                  name={item.name}
                  className="p-3 my-3 w-80 bg-gray-100 rounded"
                  placeholder={item.placeH}
                  required
                />
              </div>
            ))}
            <input
              type="date"
              className="p-3 my-3 w-80 bg-gray-100 rounded"
              name="date"
              onChange={(e) => setform({ ...form, date: e.target.value })}
            />

            <textarea
              id="description"
              name="description"
              rows="4"
              cols="50"
              className="p-2"
              placeholder="write description"
              onChange={(e) => setform({ ...form, description: e.target.value })}
            />

            {/* Image upload box for imageUrl1 */}
            <label htmlFor="fileInput1" className="cursor-pointer flex items-center justify-center border border-dashed border-[#1475cf] min-h-[120px] min-w-[120px] max-h-[120px] max-w-[120px]">
              <input
                type="file"
                id="fileInput1"
                accept="image/*"
                className="hidden"
                onChange={(e) => setFile1(e.target.files[0])}
              />
              <span className="text-[12px] text-center font-bold">(Main Image)</span>
            </label>
            {form.imageUrl1 && (
              <img
                src={form.imageUrl1}
                alt="Uploaded"
                className="w-[120px] h-[120px] border border-blue-600 object-cover"
              />
            )}

            {/* Image upload box for imageUrl2 */}
           
            <label htmlFor="fileInput2" className="cursor-pointer h flex items-center justify-center border border-dashed border-[#1475cf] min-h-[120px] min-w-[120px] max-h-[120px] max-w-[120px]">
             
              <input
                type="file"
                id="fileInput2"
                accept="image/*"
                className="hidden"
                onChange={(e) => setFile2(e.target.files[0])}
              />
              <span className="text-[12px] text-center font-bold">(Choose Pic 2)</span>
            </label>
            {form.imageUrl2 && (
              <img
                src={form.imageUrl2}
                alt="Uploaded"
                className="w-[120px] h-[120px] border border-blue-600 object-cover"
              />
            )}
          </div>

          <button
            onClick={handleSubmit}
            type="submit"
            className="bg-blue-400 py-2 px-20 rounded text-black mt-5"
          >
            {isLoading ? <Loader /> : 'Create'}
          </button>
        </form>
      </main>

      {isModalOpen && (
        <Modal onClose={() => setIsModalOpen(false)}>
          <p>{modalMessage}</p>
        </Modal>
      )}
    </>
  );
};
