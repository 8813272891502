import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { db, storage } from '../../firebase';
import { useNavigate } from 'react-router-dom';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import Loader from '../../Common/Loader';

export const AddPartnership = () => {
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(null);
  const [error, setError] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [form, setForm] = useState({
    imageUrl: '',
  });

  const [isLoading, setIsLoading] = useState(false); // Added loading state
  const [modalMessage, setModalMessage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const uploadFile = () => {
      if (file) {
        console.log('File selected:', file);
        const storageRef = ref(storage, file.name);
        const uploadFile = uploadBytesResumable(storageRef, file);
        uploadFile.on(
          'state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgress(progress);
            switch (snapshot.state) {
              case 'paused':
                console.log('upload is paused');
                break;
              case 'running':
                console.log('upload is running');
                break;
              default:
                break;
            }
          },
          (error) => {
            console.log(error);
          },
          () => {
            getDownloadURL(uploadFile.snapshot.ref).then((downloadURL) => {
              setForm((prevForm) => ({ ...prevForm, imageUrl: downloadURL }));
            });
          }
        );
      }
    };

    uploadFile();
  }, [file]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isEmptyField = Object.values(form).some((value) => value === '');
    if (isEmptyField) {
      setError({ submit: 'All Fields are required' });
      alert('All fields are required');
      return;
    }

    setIsLoading(true); // Set loading to true before API call
    setIsSubmit(true);
    try {
      const docRef = await addDoc(collection(db, 'Partnership'), {
        ...form,
        timestamp: serverTimestamp(),
      });

      setModalMessage('Data is stored in the database');
      setIsModalOpen(true);
      setTimeout(() => {
        setIsModalOpen(false);
        navigate('/');
      }, 3000); // Close modal and navigate after 3 seconds
    } catch (error) {
      console.error('Error adding document: ', error);
      setModalMessage('Error: Failed to add data to the database');
      setIsModalOpen(true);
    } finally {
      setIsLoading(false); // Set loading to false after API call regardless of success or failure
    }
  };

  return (
    <main className="mt-20 ml-20 ">
      <h1 className="w-fit mx-auto text-blue-700">Add Partnership</h1>
      <span className="flex w-[120px] h-[2px] bg-[#e1e1e1]  mx-auto mb-4">
        <em className="w-[60px] h-[2px] bg-blue-700 mx-auto" />
      </span>
      <form className="w-[80%] h-auto  p-10 bg-slate-50 shadow-lg mx-auto">
        {/* Image upload box for imageUrl */}
        <div className='flex flex-wrap gap-4'>
          <label htmlFor="fileInput" className="cursor-pointer flex items-center justify-center border border-dashed border-[#1475cf] min-h-[120px] min-w-[120px] max-h-[120px] max-w-[120px]">
            <input
              type="file"
              id="fileInput"
              accept="image/*"
              className="hidden"
              onChange={(e) => setFile(e.target.files[0])}
            />
            <span className="text-[12px] text-center font-bold">(Choose file)</span>
          </label>
          {form.imageUrl && (
            <img
              src={form.imageUrl}
              alt="Uploaded"
              className="w-[120px] h-[120px] border border-blue-600 object-cover"
            />
          )}
        </div>

        <button
          onClick={handleSubmit}
          type="submit"
          className={`bg-blue-400 py-2 px-20 rounded text-black mt-5 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
          disabled={isLoading}
        >
          {isLoading ? <Loader/> : 'Create'}
        </button>
      </form>

      {/* Modal for displaying messages */}
      {isModalOpen && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded shadow-md">
            <p>{modalMessage}</p>
          </div>
        </div>
      )}
    </main>
  );
};
