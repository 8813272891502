import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";


// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import img1 from '../../assets/images/blog/large/pic1.jpg';
import img2 from '../../assets/images/blog/large/pic2.jpg';
import img3 from '../../assets/images/blog/large/pic3.jpg';
import img4 from '../../assets/images/blog/large/pic4.jpg';
import img5 from '../../assets/images/blog/large/pic5.jpg';
import img6 from '../../assets/images/blog/large/pic6.jpg';

// import required modules
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import { useParams } from "react-router-dom";

const swiperFirst = [
    {image: img1},{image: img2},
    {image: img3},{image: img4},
    {image: img5},{image: img6},
];

export default function GallerySlider() {
  const [projectData, setProjectData] = useState([]);
  const { id } = useParams();
  const selectedProject = projectData.find(
    (project) => project.id === id
  );

  useEffect(() => {
      const fetchProjectData = async () => {
          try {
              const ProjectCollection = collection(db, 'Project');
              const snapshot = await getDocs(ProjectCollection);
              const data = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
              setProjectData(data);
          } catch (error) {
              console.error('Error fetching project data:', error);
          }
      };
      fetchProjectData();
  }, []);

  // Log the entire projectData array
  console.log(projectData);
  console.log(selectedProject)
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  return (
    <>
      <Swiper 
        spaceBetween={10}
        thumbs={{swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null}}
        modules={[FreeMode, Navigation, Thumbs]}
        className="fundraiser-gallery-swiper"
      >
            <SwiperSlide >
                <div className="dz-media"><img src={selectedProject?.imageUrl1} alt="" className="h-[16rem] md:h-[33rem]  object-cover w-full"/></div>
            </SwiperSlide>
    
      </Swiper>
      <Swiper
        onSwiper={setThumbsSwiper}
        spaceBetween={10}
        slidesPerView={4}
        freeMode={true}
        watchSlidesProgress={true}
        modules={[FreeMode, Navigation, Thumbs]}
        className="fundraiser-gallery-thumb"
      >
        
      </Swiper>
    </>
  );
}
