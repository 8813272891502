import { HiBars3, HiOutlineBars3 } from "react-icons/hi2";
import { BiCategoryAlt } from "react-icons/bi";
import { AiFillProject} from "react-icons/ai";
import { FaBlog, FaGripLines, FaHandHolding, FaUsers } from "react-icons/fa";
import { GrStatusGood } from "react-icons/gr";
import { PiCertificateFill } from "react-icons/pi";
import { RiGalleryFill } from "react-icons/ri";
import { GiKnightBanner } from "react-icons/gi";
export const Dashboard_sidebar_links = [

  {
    key:"2",
    label: " Founder",
    path: "/viewfounder",
    icon: <FaUsers />,
  },
  {
    key:"3",
    label: " Partnership ",
    path: "/viewpartnership",
    icon: <FaHandHolding />,
  },
  {
    key:"4",
    label: " Testimonial ",
    path: "/viewtestimonial",
    icon: <FaGripLines />,
  },

  {
    key:"5",
    label: "Category ",
    path: "/viewcategory",
    icon: <BiCategoryAlt />,
  },

  {
    key:"7",
    label: " Blog ",
    path: "/viewnews",
    icon: <FaBlog />,
  },

  {
    key:"9",
    label: "Success Story ",
    path: "/viewsuccessstory",
    icon: <GrStatusGood />,
  },
  {
    key:"12",
    label: "Project ",
    path: "/viewproject",
    icon: <AiFillProject />,
  },

  {
    key:"14",
    label: "Certificate ",
    path: "/viewcertificate",
    icon:<PiCertificateFill />,
  },
  {
    key:"15",
    label: "Gallery ",
    path: "/viewgallery",
    icon: <RiGalleryFill />

  },
  {
    key:"16",
    label: "HomeBanner ",
    path: "/viewhomebanner",
    icon: <GiKnightBanner />,
  },

];

