import { useState } from "react"
import { CounterData } from "../../Common/Dashboard_index"
import { addDoc, collection, serverTimestamp } from "firebase/firestore"
import { useNavigate } from "react-router-dom"
import { db } from "../../firebase"

export const AddCounterData = () => {
    const [form,setform]=useState({
        title:"",
        number:""
    })
    const handleChange=(e)=>{
    const {name,value}=e.target;
setform({...form,[name]:value});
    }
    const navigate = useNavigate();
    const [isSubmit, setIsSubmit] = useState(false);
    const [error, seterror] = useState({});
    const handleSubmit=async(e)=>{
        e.preventDefault();
        const isEmptyField=Object.values(form).some((value)=>value==="");
        if(isEmptyField){
            seterror({submit:"All fields are requried"});
            alert('All  fields are required');
            return;
        }
        setIsSubmit(true);
        try{
            const docRef=await addDoc(collection(db,"Counter"),{
                ...form,
                timestamp: serverTimestamp(),
            })
            alert("Data is stored in the database");
            navigate("/");
        }
        catch(error){
            console.log("error adding documnet",error)
        }
    }
  return (
  <>
    <div>
    <main className="mt-20 ml-20 ">
      <h1 className="w-fit mx-auto text-blue-700">Add Counter</h1>
      <span className="flex w-[120px] h-[2px] bg-[#e1e1e1]  mx-auto mb-4">
        <em className="w-[60px] h-[2px] bg-blue-700 mx-auto" />
      </span>
      <form className="w-[60%] h-auto  p-10 bg-slate-50 shadow-lg mx-auto">
        <div className="flex flex-wrap justify-between gap-4">
        {CounterData.map((item)=>(
          <div key={item}>
          <input
            onChange={handleChange}
            value={form[item.name]}
            type={item.type}
            name={item.name} // Fix: Specify the name attribute
            className="p-3 my-3 bg-gray-100  rounded"
            placeholder={item.placeH}
            required
          />
               </div>
        ))}
        </div>

        <button
          onClick={handleSubmit}
          type="submit"
          className="bg-blue-400 py-2 px-20 rounded text-black mt-5"
        >
          Create
        </button>
      </form>
    </main>
    </div>
  </>
  )
}
