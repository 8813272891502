import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import NewsSlider from "../components/Home/NewsSlider";
import bg from "../assets/images/blog/Blog.webp";
import BlogDetailsLeftBar from "../components/BlogDetailsLeftBar";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";

const BlogDetails = () => {
  const [NewsData, setNewsData] = useState([]);
  const [latestNewsdata, setLatestNewsData] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    const fetchNews = async () => {
      const NewsCollection = collection(db, "News");
      const snapshot = await getDocs(NewsCollection);
      const Newsdatas = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      Newsdatas.sort((a, b) => new Date(b.date) - new Date(a.date));
      const latest4News = Newsdatas.slice(0, 4);
      setNewsData(Newsdatas);
      setLatestNewsData(latest4News);
    };
    fetchNews();
    console.log(NewsData);
    console.log(latestNewsdata);
  }, []);

  return (
    <>
      <div className="page-content bg-white overflow-x-hidden">
        <div className="relative">
          <img
            src={bg}
            alt=""
            className="w-full h-[20rem] md:h-[32rem] object-cover"
          />
          {/* Background color overlay */}
          <div className="absolute inset-0 bg-[#6b696a] bg-opacity-50"></div>
          {/* Content */}
          <span className="absolute md:bottom-[12rem] bottom-[8rem] flex bg-transparent w-full md:h-20 p-2 rounded text-center text-black font-extrabold text-2xl md:text-6xl justify-center mx-4">
            <div className="dz-bnr-inr-entry  w-full">
              <nav aria-label="breadcrumb" className="breadcrumb-row">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item active">Blog</li>
                </ul>
              </nav>
            </div>
          </span>
        </div>
        <section className="content-inner-2">
          <div className="container">
            <div className="row ">
              <div className="col-xl-8  col-lg-8 m-b30">
                <BlogDetailsLeftBar />
              </div>
              <div className="col-xl-4 col-lg-4">
                <aside className="side-bar sticky-top">
                  <div className="widget style-1 recent-posts-entry">
                    <div className="widget-title">
                      <h5 className="title">Recent Post</h5>
                    </div>
                    <div className="widget-post-bx">
                      {latestNewsdata.map((item, ind) => (
                        <div className="widget-post clearfix" key={ind}>
                          <div className="dz-media ">
                            <img
                              src={item.imageUrl1}
                              alt=""
                              className="w-full "
                            />
                          </div>
                          <div className="dz-info">
                            <h6 className="title ">
                              <Link to={`/blog-details/${item.id}`}>
                                {item.title}
                              </Link>
                            </h6>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </section>
        <section className="content-inner-2 ">
          <div className="container">
            <div className="section-head">
              <h2 className="title">Related Articles</h2>
            </div>
            <NewsSlider />
          </div>
        </section>
      </div>
    </>
  );
};

export default BlogDetails;
