// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getStorage} from 'firebase/storage';
import { getFirestore } from 'firebase/firestore';
import {getAuth} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBQTMMCmhBRypUuiptSHvazPfno5_VBSPg",
  authDomain: "qubafoundation-54e9b.firebaseapp.com",
  projectId: "qubafoundation-54e9b",
  databaseURL: "https://qubafoundation-54e9b-default-rtdb.firebaseio.com/",
  storageBucket: "qubafoundation-54e9b.appspot.com",
  messagingSenderId: "9792407264",
  appId: "1:9792407264:web:e502064c69c41a780af285",
  measurementId: "G-V9Q727GH6X"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth=getAuth(app);
const storage=getStorage(app)

export { app, analytics,db,auth,storage };
