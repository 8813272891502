import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'
import { Sidebar } from './Sidebar'
import  Header  from './Header'
 
export const Dashboard_Layout = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  return (
<>
<div className="flex h-screen overflow-hidden fixed top-0 left-0">
      <Sidebar />
      <div className="flex flex-1 flex-col ">
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      </div>
    </div>
</>
  )
}
