import React, { useState, useEffect } from "react";
import { storage, db } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import Loader from "../../Common/Loader";
import Modal from "../../Common/Modal";
import { Testimonial } from "../../Common/Dashboard_index";

export const AddTestimonial = () => {
  const [form, setform] = useState({
    author: "",
    position: '',
    description: "",
    imageUrl: '', // Added imageUrl field
  });
  const [file, setfile] = useState(null);
  const [progress, setProgress] = useState(null);
  const [error, seterror] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Added loading state
  const [modalMessage, setModalMessage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const uploadFile = () => {
      if (file) {
        const storageRef = ref(storage, file.name);
        const uploadFile = uploadBytesResumable(storageRef, file);
  
        uploadFile.on(
          "state_changed",
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgress(progress);
            switch (snapshot.state) {
              case "paused":
                console.log("upload is paused");
                break;
              case "running":
                console.log("upload is running");
                break;
              default:
                break;
            }
          },
          (error) => {
            console.log(error);
          },
          () => {
            getDownloadURL(uploadFile.snapshot.ref).then((downloadURL) => {
              setform((prevForm) => ({ ...prevForm, imageUrl: downloadURL }));
            });
          }
        );
      }
    };

    file && uploadFile();
  }, [file]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setform({ ...form, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isEmptyField = Object.values(form).some((value) => value === "");
    if (isEmptyField) {
      seterror({ submit: "All Fields are required" });
      return;
    }

    setIsLoading(true);
    setIsSubmit(true);
    try {
      const docRef = await addDoc(collection(db, "Testimonials"), {
        ...form,
        timestamp: serverTimestamp(),
      });

      setModalMessage('Data is stored in the database');
      setIsModalOpen(true);
      setTimeout(() => {
        setIsModalOpen(false);
        navigate("/");
      }, 3000); // Close modal and navigate after 3 seconds
    } catch (error) {
      console.error("Error adding document: ", error);
      setModalMessage('Error: Failed to add data to the database');
      setIsModalOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <main className="mt-20 ml-20 ">
      <h1 className="w-fit mx-auto text-blue-700">Add Testimonial</h1>
      <span className="flex w-[120px] h-[2px] bg-[#e1e1e1]  mx-auto mb-4">
        <em className="w-[60px] h-[2px] bg-blue-700 mx-auto" />
      </span>
      <form className="w-[80%] h-auto  p-10 bg-slate-50 shadow-lg mx-auto">
        <div className="flex flex-wrap justify-between gap-4">
          {Testimonial.map((item) => (
            <div key={item.name} className="flex flex-col">
            <label className="text-sm font-semibold text-black">{item.placeH}</label>
              <input
                onChange={handleChange}
                value={form[item.name]}
                type={item.type}
                name={item.name}
                className="p-3 my-3 w-96 bg-gray-100  rounded"
                placeholder={item.placeH}
                required
              />
            </div>
          ))}
          <textarea
            name="description"
            rows="4"
            cols="50"
            className="p-2"
            placeholder="write description"
            onChange={(e) => setform({ ...form, description: e.target.value })}
          />
          {/* Image upload box for imageUrl */}
          <label htmlFor="fileInput" className="cursor-pointer flex items-center justify-center border border-dashed border-[#1475cf] min-h-[120px] min-w-[120px] max-h-[120px] max-w-[120px]">
            <input
              type="file"
              id="fileInput"
              accept="image/*"
              className="hidden"
              onChange={(e) => setfile(e.target.files[0])}
            />
            <span className="text-[12px] text-center font-bold">(Choose file)</span>
          </label>
          {form.imageUrl && (
            <img
              src={form.imageUrl}
              alt="Uploaded"
              className="w-[120px] h-[120px] border border-blue-600 object-cover"
            />
          )}
        </div>

        <button
          onClick={handleSubmit}
          type="submit"
          className="bg-blue-400 py-2 px-20 rounded text-black mt-5"
        >
          {isLoading ? <Loader /> : 'Create'}
        </button>
      </form>

      {isModalOpen && (
        <Modal onClose={() => setIsModalOpen(false)}>
          <p>{modalMessage}</p>
        </Modal>
      )}
    </main>
  );
};
