import React, { useEffect, useState } from 'react';
import { doc, getDoc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { db, storage } from '../../firebase';
import { useNavigate, useParams } from 'react-router-dom';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';

export const EditBanner = () => {
  const [error, setError] = useState({});
  const [file1, setFile1] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const [isSubmit, setIsSubmit] = useState(false);
  const [form, setForm] = useState({
    Title: '',
    description: '',
    imageUrl1: '',
  });
  const [uploadedImageUrl, setUploadedImageUrl] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const BannerDoc = await getDoc(doc(db, 'HomeBanner', id));
        if (BannerDoc.exists()) {
          const BannerData = BannerDoc.data();
          setForm(BannerData);
          setUploadedImageUrl(BannerData.imageUrl1); // Set the image URL for display
        } else {
          console.error('Banner image not found');
        }
      } catch (error) {
        console.error('Error fetching banner image data:', error);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    const uploadFile = async (file, imageUrlKey) => {
      const storageRef = ref(storage, file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);

      try {
        await uploadTask;

        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

        setForm((prevForm) => ({ ...prevForm, [imageUrlKey]: downloadURL }));
        setUploadedImageUrl(downloadURL); // Update the displayed image URL
      } catch (error) {
        console.error('Error uploading file: ', error);
      }
    };

    if (file1) {
      uploadFile(file1, 'imageUrl1');
    }
  }, [file1]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isEmptyField = Object.values(form).some((value) => value === '');
    if (isEmptyField) {
      setError({ submit: 'All Fields are required' });
      alert('All fields are required');
      return;
    }

    setIsSubmit(true);

    try {
      const counterRef = doc(db, 'HomeBanner', id);

      await updateDoc(counterRef, {
        ...form,
        timestamp: serverTimestamp(),
      });

      navigate('/viewhomebanner');
    } catch (error) {
      console.error('Error updating document: ', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setForm((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleChangeFile = (e) => {
    const newFile = e.target.files[0];
    setFile1(newFile);

    const objectURL = URL.createObjectURL(newFile);
    setUploadedImageUrl(objectURL);
  };

  return (
    <main className="mt-20 ml-20">
      <h1 className="w-fit mx-auto text-blue-700">Edit HomeBanner</h1>
      <span className="flex w-[120px] h-[2px] bg-[#e1e1e1] mx-auto mb-4">
        <em className="w-[60px] h-[2px] bg-blue-700 mx-auto" />
      </span>
      <form className="w-[80%] h-auto p-10 bg-slate-50 shadow-lg mx-auto">
        <div className="flex flex-wrap justify-between gap-4">
        <div className='flex flex-col'>
          <label className="text-sm font-semibold text-black">Title</label>
          <input
            onChange={handleChange}
            value={form.Title}
            type="text"
            name="Title"
            className="p-3 w-96 my-3 bg-gray-100 rounded"
            placeholder="Title"
            required
          />
          </div>
          <div className='flex flex-col'>
          <label className="text-sm font-semibold text-black">Description</label>
          <textarea
            id="description"
            name="description"
            rows="4"
            cols="50"
            className='bg-gray-100 p-2'
            placeholder="write description"
            value={form.description} // Set the value to display the stored description
            onChange={(e) => handleChange(e)}
          />
          </div>

          <label htmlFor="fileInput" className="cursor-pointer flex items-center justify-center border border-dashed border-[#1475cf] min-h-[120px] min-w-[120px] max-h-[120px] max-w-[120px]">
            <input
              type="file"
              id="fileInput"
              accept="image/*"
              className="hidden"
              onChange={handleChangeFile}
            />
            <span className="text-[12px] text-center font-bold">(Choose file)</span>
          </label>
          {uploadedImageUrl && (
            <img
              src={uploadedImageUrl}
              alt="Uploaded"
              className="w-[120px] h-[120px] border border-blue-600 object-cover"
            />
          )}
        </div>

        <button
          onClick={handleSubmit}
          type="submit"
          className="bg-blue-400 py-2 px-20 rounded text-black mt-5"
        >
          Update
        </button>
      </form>
    </main>
  );
};
