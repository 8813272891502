import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LightGallery from "lightgallery/react";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
// import plugins if you need

//Images
import bgImage from "./../assets/images/banner/certificatebanner.jpg";

//Component
import PageBanner from "./../layouts/PageBanner";
import UpdateBlog from "./../components/Home/UpdateBlog";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import Modal from "../Common/Modal";

const Certificates = () => {
  const [certificate, setcertificate] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImg, setSelectedImg] = useState("");
  useEffect(() => {
    const fetchCategory = async () => {
      const CertificateCollection = collection(db, "Certificate");
      const snapshot = await getDocs(CertificateCollection);
      const certificateData = snapshot.docs.map((doc) => doc.data());
      setcertificate(certificateData);
    };
    fetchCategory();
  }, []);
  const onInit = () => {
    //  console.log('lightGallery has been initialized');
  };

  const handleImgModalSelect = (imgUrl) => {
    setSelectedImg(imgUrl);
    setIsModalOpen(true);
  };
  return (
    <>
      <div className="page-content bg-white min-h-[100vh] overflow-x-hidden">
        <div className="relative">
          <img
            src={bgImage}
            alt=""
            className="w-full h-[20rem] md:h-[32rem] object-cover"
          />
          {/* Background color overlay */}
          <div className="absolute inset-0 bg-[#6b696a] bg-opacity-50"></div>
          {/* Content */}
          <span className="absolute md:bottom-[12rem] bottom-[8rem] flex bg-transparent w-full md:h-20 p-2 rounded text-center text-black font-extrabold text-2xl md:text-6xl justify-center mx-4">
            <div className="dz-bnr-inr-entry  w-full">
              <nav aria-label="breadcrumb" className="breadcrumb-row">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item active">Certificate</li>
                </ul>
              </nav>
            </div>
          </span>
        </div>

        <div className="content-inner h-auto">
          <div className="flex flex-row flex-wrap gap-2 md:gap-60 mx-10">
            {certificate.map((item, index) => (
              <div
                data-exthumbimage={item.image}
                data-src={item.image}
                title="Image"
                className="flex sm:gap-10 mx-[2px] sm:mx-[10px]"
                key={index}
                onClick={() => handleImgModalSelect(item.imageUrl1)}
              >
                <img
                  src={item.imageUrl1}
                  alt=""
                  className="lg:h-72 lg:w-96 sm:h-40 sm:w-40 shadow-xl w-28  h-20 object-cover"
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      <Modal isOpen={isModalOpen} setIsOpen={setIsModalOpen}>
        <div className="w-full lg:w-[600px] lg:max-w-[600px] h-[300px] lg:h-[600px]">
          <img
            src={selectedImg}
            alt="certificate"
            className="w-full lg:w-[600px] h-[300px] lg:h-[600px] object-cover object-fit"
          />
        </div>
      </Modal>
    </>
  );
};

export default Certificates;
