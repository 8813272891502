import React, { useEffect, useState } from 'react';
import Buttons from '../../Common/Buttons';
import { MdOutlineDeleteOutline } from 'react-icons/md';
import { collection, deleteDoc, doc, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import { useNavigate } from 'react-router-dom';
import { FaRegEdit } from 'react-icons/fa';
import { DeleteModal } from '../../Common/DeleteModal';

const iconClass = "text-xl";
const tableData = "border-b border-[#eee] py-3 px-4 ";
const heading = "py-4 text-xl font-bold text-gray-800 pl-4 p-10 bg-blue-100";

export const ViewCertificate = () => {
  const [ProjectData, setProjectData] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategoryData = async () => {
      const CategoryCollection = collection(db, "Certificate");
      const snapshot = await getDocs(CategoryCollection);
      const data = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setProjectData(data);
    };
    fetchCategoryData();
  }, []);

  const handleDelete = (id) => {
    setSelectedItemId(id);
    setIsDeleteModalOpen(true);
  };

  const confirmDelete = async () => {
    try {
      const ProjectRef = doc(db, "Certificate", selectedItemId);
      await deleteDoc(ProjectRef);
      setProjectData(ProjectData.filter((item) => item.id !== selectedItemId));
      setIsDeleteModalOpen(false);
    } catch (error) {
      console.log("error deleting document", error);
    }
  };

  const handleEdit = (id) => {
    try {
      const foundCertificate = ProjectData?.find((item) => item.id === id);
      navigate(`/editcertificate/${id}`, { state: { editData: foundCertificate } });
    } catch (error) {
      console.log("error", error);
    }
  };

  const closeModal = () => {
    setIsDeleteModalOpen(false);
  };

  return (
    <div className='ml-40 px-5 pt-6 pb-2.5'>
      <div className="flex justify-end mb-10">
        <Buttons type="button" variant="secondary" to="/addcertificate">
          Add
        </Buttons>
      </div>

      <div className="rounded-sm bg-white shadow-default sm:px-7.5 xl:pb-1">
        <div className="overflow-x-auto border border-gray-100">
          <table className="w-full table-auto border-collapse">
            <thead>
              <tr className="bg-gray-200 text-left">
                <th className={heading}>Image</th>
                <th className={heading}>Action</th>
              </tr>
            </thead>
            <tbody>
              {ProjectData && ProjectData.length > 0 ? (
                ProjectData.map((data, key) => (
                  <tr key={key} className={key % 2 === 0 ? "bg-gray-100" : ""}>
                    <td className={tableData}>
                      <img
                        alt="founderimage"
                        src={data.imageUrl1}
                        className="h-20 w-20 object-cover border border-gray-100"
                      />
                    </td>

                    <td className={tableData}>
                      <div className="flex items-center space-x-3.5">
                        <span className="hover:text-primary cursor-pointer">
                          <FaRegEdit
                            className={iconClass}
                            onClick={() => handleEdit(data.id)}
                          />
                        </span>

                        <span
                          className="hover:text-primary cursor-pointer"
                          onClick={() => handleDelete(data.id)}
                        >
                          <MdOutlineDeleteOutline className={iconClass} />
                        </span>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="2" className="text-center text-[20px] py-5">
                    No Data Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <DeleteModal
        isModalOpen={isDeleteModalOpen}
        closeModal={closeModal}
        titleName={`Certificate ${selectedItemId}`}
        handleDelete={confirmDelete}
      />
    </div>
  );
};
