import React, { useEffect, useState } from 'react';
import { Founder } from '../../Common/Dashboard_index';
import { doc, getDoc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { db, storage } from '../../firebase';
import { useNavigate, useParams } from 'react-router-dom';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import Modal from '../../Common/Modal';
import Loader from '../../Common/Loader';

const EditFounder = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [file1, setFile1] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [progress, setProgress] = useState(null);
  const [form, setForm] = useState({
    name: '',
    position: '',
    imageUrl1: '',
  });

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); // Set loading to true before making the API call
      try {
        const founderDoc = await getDoc(doc(db, 'Founder', id));
        if (founderDoc.exists()) {
          setForm(founderDoc.data());
        } else {
          console.error('Founder not found');
        }
      } catch (error) {
        console.error('Error fetching Founder data:', error);
      } finally {
        setIsLoading(false); // Set loading to false once data is fetched
      }
    };

    fetchData();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setForm((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isEmptyField = Object.values(form).some((value) => value === '');
    if (isEmptyField) {
      setError({ submit: 'All Fields are required' });
      alert('All fields are required');
      return;
    }

    setIsSubmit(true);

    try {
      const founderRef = doc(db, 'Founder', id);
      await updateDoc(founderRef, {
        ...form,
        timestamp: serverTimestamp(),
      });
      setModalMessage('Data is stored in the database');
      setIsModalOpen(true);
      setTimeout(() => {
        setIsModalOpen(false);
        navigate('/viewfounder');
      }, 1000);
    } catch (error) {
      console.error('Error updating document: ', error);
    }
  };

  const uploadFile = async (file, imageUrlKey) => {
    const storageRef = ref(storage, file.name);
    const uploadTask = uploadBytesResumable(storageRef, file);

    try {
      await uploadTask;
      const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
      setForm((prevForm) => ({ ...prevForm, [imageUrlKey]: downloadURL }));
    } catch (error) {
      console.error('Error uploading file: ', error);
    }
  };

  useEffect(() => {
    if (file1) {
      uploadFile(file1, 'imageUrl1');
    }
  }, [file1]);

  return (
    <>
      <main className="mt-20 ml-20">
        <h1 className="w-fit mx-auto text-blue-700">Edit Founder</h1>
        <span className="flex w-[120px] h-[2px] bg-[#e1e1e1] mx-auto mb-4">
          <em className="w-[60px] h-[2px] bg-blue-700 mx-auto" />
        </span>
        <form className="w-[80%] h-auto p-10 bg-slate-50 shadow-lg mx-auto">
          <div className="flex flex-wrap gap-10">
            <div className="flex flex-wrap gap-20">
              <label
                htmlFor="fileInput"
                className="cursor-pointer flex items-center justify-center border border-dashed border-[#1475cf] min-h-[120px] min-w-[120px] max-h-[120px] "
              >
                <input
                  type="file"
                  id="fileInput"
                  accept="image/*"
                  className="hidden"
                  onChange={(e) => setFile1(e.target.files[0])}
                />
                <span className="text-[12px] text-center font-bold">(Choose file)</span>
              </label>
              {/* Conditionally render the image when data is available */}
              {!isLoading && form.imageUrl1 && (
                <img
                  src={form.imageUrl1}
                  alt="Current Image"
                  className="w-[120px] h-[120px] border border-blue-600 object-cover mt-4"
                />
              )}
            </div>
          </div>

          <div className="flex flex-wrap mt-10  py-4">
            {Founder.map((item) => (
              <div key={item.name} className="w-full  md:w-1/3 mt-4 lg:w-1/3">
                <label className="block text-sm font-medium text-gray-700">{item.placeH}</label>
                <input
                  onChange={handleChange}
                  value={form[item.name]}
                  type={item.type}
                  name={item.name}
                  className="mt-1 p-3 gap-10 w-80 bg-gray-100 rounded"
                  placeholder={item.placeH}
                  required
                />
              </div>
            ))}
          </div>

          <button
            onClick={handleSubmit}
            type="submit"
            className="bg-blue-400 py-2 px-20 rounded text-black mt-5"
          >
            {isLoading ? <Loader /> : 'Update'}
          </button>
        </form>
      </main>
      {isModalOpen && (
        <Modal onClose={() => setIsModalOpen(false)}>
          <p>{modalMessage}</p>
        </Modal>
      )}
    </>
  );
};

export default EditFounder;
