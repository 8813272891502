import React, { useEffect, useState } from 'react';
import { doc, serverTimestamp, updateDoc, getDoc } from 'firebase/firestore';
import { db, storage } from '../../firebase';
import { useNavigate, useParams } from 'react-router-dom';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import Loader from '../../Common/Loader'; // Import the Loader component

export const EditPartnership = () => {
  const [isSubmit, setIsSubmit] = useState(false);
  const [form, setForm] = useState({
    imageUrl: '',
  });
  const [file1, setFile1] = useState(null);
  const [uploadedImageUrl, setUploadedImageUrl] = useState('');
  const [error, setError] = useState({});
  const [isLoading, setIsLoading] = useState(false); // Add isLoading state
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); // Show loader while fetching data
      try {
        const partnershipDoc = await getDoc(doc(db, 'Partnership', id));
        if (partnershipDoc.exists()) {
          const partnershipData = partnershipDoc.data();
          setForm(partnershipData);
          setUploadedImageUrl(partnershipData.imageUrl);
        } else {
          console.error('Partnership not found');
        }
      } catch (error) {
        console.error('Error fetching Partnership data:', error);
      } finally {
        setIsLoading(false); // Hide loader after data is fetched
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    const uploadFile = async (file, imageUrlKey) => {
      const storageRef = ref(storage, file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);

      try {
        await uploadTask;

        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

        setForm((prevForm) => ({ ...prevForm, [imageUrlKey]: downloadURL }));
        setUploadedImageUrl(downloadURL);
      } catch (error) {
        console.error('Error uploading file: ', error);
      }
    };

    if (file1) {
      uploadFile(file1, 'imageUrl');
    }
  }, [file1]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isEmptyField = Object.values(form).some((value) => value === '');
    if (isEmptyField) {
      setError({ submit: 'All Fields are required' });
      alert('All fields are required');
      return;
    }

    setIsSubmit(true);

    try {
      const partnershipRef = doc(db, 'Partnership', id);

      setIsLoading(true); // Show loader before API call

      await updateDoc(partnershipRef, {
        ...form,
        timestamp: serverTimestamp(),
      });
      navigate('/viewpartnership');
    } catch (error) {
      console.error('Error updating document: ', error);
    } finally {
      setIsLoading(false); // Hide loader after API call
    }
  };

  return (
    <main className="mt-20 ml-20">
      <h1 className="w-fit mx-auto text-blue-700">Edit Partnership</h1>
      <span className="flex w-[120px] h-[2px] bg-[#e1e1e1] mx-auto mb-4">
        <em className="w-[60px] h-[2px] bg-blue-700 mx-auto" />
      </span>
      <form className="w-[80%] h-auto p-10 bg-slate-50 shadow-lg mx-auto">
        <div className="flex flex-wrap gap-20">
          <label htmlFor="fileInput" className="cursor-pointer flex items-center justify-center border border-dashed border-[#1475cf] min-h-[120px] min-w-[120px] max-h-[120px] ">
            <input
              type="file"
              id="fileInput"
              accept="image/*"
              className="hidden"
              onChange={(e) => setFile1(e.target.files[0])}
            />
            <span className="text-[12px] text-center font-bold">(Choose file)</span>
          </label>
          {form.imageUrl && (
            <img
              src={form.imageUrl}
              alt="Uploaded"
              className="w-[120px] h-[120px] border border-blue-600 object-cover"
            />
          )}
        </div>

        <button
          onClick={handleSubmit}
          type="submit"
          className="bg-blue-400 py-2 px-20 rounded text-black mt-5"
          disabled={isLoading} // Disable button while loading
        >
          {isLoading ? <Loader /> : 'Update'}
        </button>
      </form>
    </main>
  );
};
