import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";

// Import Swiper styles
import "swiper/css";

import { Autoplay } from "swiper/modules";

//SwiperCore.use([EffectCoverflow,Pagination]);

const TeamSlider = () => {
  const [founders, setFounders] = useState([]);
  useEffect(() => {
    const fetchFounders = async () => {
      const founderCOllection = collection(db, "Founder");
      const snapshot = await getDocs(founderCOllection);
      const founderData = snapshot.docs.map((doc) => doc.data());
      setFounders(founderData);
    };
    fetchFounders();
    console.log(fetchFounders);
  }, []);
  console.log(founders);

  return (
    <>
      <Swiper
        className="team-slider"
        speed={1500}
        //parallax= {true}
        slidesPerView={4}
        spaceBetween={5}
        loop={true}
        autoplay={{
          delay: 3000,
        }}
        modules={[Autoplay]}
        breakpoints={{
          1200: {
            slidesPerView: 5,
          },
          992: {
            slidesPerView: 4,
          },
          768: {
            slidesPerView: 3,
          },
          575: {
            slidesPerView: 2,
          },
          420: {
            slidesPerView: 2,
          },
          320: {
            slidesPerView: 1,
          },
        }}
      >
        {founders.map((founder, i) => (
          <SwiperSlide key={i}>
            <div className="dz-team style-1 shadow-xl border border-gray-100 relative overflow-hidden">
              <div className="dz-media">
                <Link to={"/instructor"}>
                  <img
                    src={founder.imageUrl1}
                    alt=""
                    className="h-[25rem] w-full sm:w-40 object-cover"
                  />
                  <div className="absolute bottom-0 left-0 right-0 p-4 bg-gray-800 bg-opacity-50 text-center ">
                    <h5 className="text-white text-[20px]  font-bold">
                      {founder.name}
                    </h5>
                    <span className=" text-[#46d46e] font-bold text-[16px]">
                      {founder.position}
                    </span>
                  </div>
                </Link>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default TeamSlider;
