import React, { useEffect, useState } from 'react';
import {Link} from 'react-router-dom';

import PageBanner from '../layouts/PageBanner';
import CountUp from 'react-countup';


import TestimonialSlider from '../components/Home/TestimonialSlider';
import UpdateBlog from '../components/Home/UpdateBlog';

import bg from '../assets/images/banner/bnr3.jpg';
import large4 from '../assets/images/project/large/pic4.jpg';
import large5 from '../assets/images/project/large/pic5.jpg';
import large6 from '../assets/images/project/large/pic6.jpg';

import avat1 from '../assets/images/avatar/avatar1.jpg';
import avat2 from '../assets/images/avatar/avatar2.jpg';
import avat3 from '../assets/images/avatar/avatar3.jpg';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';



const ProjectStory = () => {
    const [SuccessData,setSuccessData]=useState([]);
    useEffect(() => {
        const fetchNews = async () => {
            const SuccessCollection = collection(db, 'SuccessStory');
            const snapshot = await getDocs(SuccessCollection);
            const successData = snapshot.docs.map(doc => doc.data());
            setSuccessData(successData);
    
          
        };
    
        fetchNews();
    }, []);
    function isScrolledIntoView(elem){
        const spliBox = document.querySelectorAll(elem);        
        spliBox.forEach(myFunction);
        function myFunction(item, index) {
            console.log('splitbox',item);
            const docViewTop = window.scrollY;
            const docViewBottom = docViewTop + window.innerHeight;
            let elemTop = item.getBoundingClientRect().top + docViewTop;
            const elemBottom = elemTop + item.offsetHeight;
            if((elemBottom <= docViewBottom) && (elemTop >= docViewTop)){                
                item.classList.add('split-active');
            }
        }
         
    }
    window.addEventListener("scroll", () => {
        isScrolledIntoView('.split-box');
    }); 
    return (
        <>
            <div className="page-content  bg-white overflow-x-hidden">
                <div className='relative'>
                    <img src={bg} alt="" className='w-full h-[20rem] md:h-[32rem] object-cover ' />
                    {/* Background color overlay */}
                    <div className='absolute inset-0 bg-[#6b696a] bg-opacity-50'></div>
                    {/* Content */}
                    <span className='absolute md:bottom-[12rem] bottom-[8rem] flex bg-transparent w-full md:h-20 p-2 rounded text-center text-black font-extrabold text-2xl md:text-6xl justify-center mx-4'>
                        <div className="dz-bnr-inr-entry  w-full">

                            <nav aria-label="breadcrumb" className="breadcrumb-row">
                                <ul className="breadcrumb">

                                    <li className="breadcrumb-item active">Project story</li>
                                </ul>
                            </nav>
                        </div>
                    </span>
                </div>
                {SuccessData.map((SuccessData,index)=>
                ( <section className="content-inner-2" key={index}>
                    <div className="container">						
                        <div className="row align-items-center project-bx left m-b20">
                            <div className="col-lg-6 col-md-12 m-b30">
                                <div className=" ">
                                    <div>
                                        <img src={SuccessData.imageUrl1} alt="story" className="w-full md:h-[27rem] h-[10rem] rounded-[4px] "/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 m-b30">
                                <h3 className="m-b2 title text-[#181C31] font-bold text-[24px]">{SuccessData.title}</h3>
                                <p className="m-b0 text-[16px] pt-4 pb-4  font-medium text-justify">{SuccessData.description}</p>
                               
                                <div className="author-wrappper gap-4">
                                    <div className=" w-10 h-10 rounded-full">
                                        <img src={SuccessData.imageUrl2} alt="story" className=' w-10 h-10 rounded-full object-cover' /> 
                                    </div>
                                    <div className="author-content">
                                        <div className="author-head">
                                            <h6 className="author-name font-bold text-[16px]">{SuccessData.author}</h6>
                                          
                                        </div>
                                        <ul className="author-meta">
                                            <li className="campaign">{SuccessData.no_campaign}campaign</li>
                                            <li className="location font-normal">{SuccessData.Address}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>	
                      
                    </div>
                </section>))}
               
                <section className="testimonial-wrapper1 content-inner-2">
                    <div className="section-head text-center">
                        <h2 className="title font-bold">TESTIMONIALS</h2>
                       
                    </div>
                    <TestimonialSlider />
                </section>
             
            </div>  
        </>
    );
};

export default ProjectStory;