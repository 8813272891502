import React from 'react'

export const AdvanceTable = ({columns,data}) => {
  return (
 <>
    <table className="w-full divide-y h-[500px]">
      <thead>
        <tr>
          {columns &&
            columns.map((column, index) => (
              <th
                key={index}
                className="text-left py-3 text-sm font-semibold text-gray-800 px-1 w-52"
              >
                {column.header}
              </th>
            ))}
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200">
        {Array.isArray(data) &&
          data.length > 0 &&
          data.map((item, rowIndex) => (
            <tr
              className={`${
                rowIndex % 2 == 0 ? "bg-gray-100/50" : "bg-gray-200/70"
              }`}
              key={rowIndex}
            >
              {columns &&
                columns.map((column, colIndex) => {
                  const cellData = column.accessor(item);
                  const renderedData = column.render
                    ? column.render(cellData)
                    : cellData;
                  return (
                    <td
                      key={colIndex}
                      className="py-2 text-[12px] font-semibold text-gray-500 whitespace-nowrap text-left px-1 w-52 truncate"
                    >
                      {renderedData}
                    </td>
                  );
                })}
            </tr>
          ))}
      </tbody>
    </table>
    
  );




 </>
  )
}
export default AdvanceTable;
