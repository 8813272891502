import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CiCalendarDate, CiUser } from "react-icons/ci";

//images
import bg from "../assets/images/blog/Blog.webp";

import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";

export const BlogGrid = () => {
  const [NewsData, setNewsData] = useState([]);
  useEffect(() => {
    const fetchNews = async () => {
      const NewsCollection = collection(db, "News");
      const snapshot = await getDocs(NewsCollection);
      const Newsdata = snapshot.docs.map((doc) => ({
        id: doc.id, // Access the document ID
        ...doc.data(),
      }));
      setNewsData(Newsdata);
    };
    fetchNews();
    console.log(NewsData);
  }, []);

  return (
    <>
      <div className="page-content bg-white overflow-x-hidden">
        <div className="relative">
          <img
            src={bg}
            alt=""
            className="w-full h-[20rem] md:h-[32rem] object-cover"
          />
          {/* Background color overlay */}
          <div className="absolute inset-0 bg-[#6b696a] bg-opacity-50"></div>
          {/* Content */}
          <span className="absolute md:bottom-[12rem] bottom-[8rem] flex bg-transparent w-full md:h-20 p-2 rounded text-center text-black font-extrabold text-2xl md:text-6xl justify-center mx-4">
            <div className="dz-bnr-inr-entry  w-full">
              <nav aria-label="breadcrumb" className="breadcrumb-row">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item active">Blog</li>
                </ul>
              </nav>
            </div>
          </span>
        </div>
        <div className="flex flex-wrap  sm:mx-4 md:mx-8 mt-4 sm:mt-0 lg:mx-16 xl:mx-16">
          {NewsData.map((item, i) => (
            <div
              className="flex flex-wrap mx-4 sm:w-full w-full md:w-1/2 lg:w-1/3 xl:w-1/4 shadow-xl shadow-gray-200 sm:mt-10 mb-10"
              key={i}
            >
              <div
                className="dz-card style-1 wow fadeInUp w-full"
                data-wow-delay="0.2s"
                key={i}
              >
                <div className="dz-media w-full">
                  <Link to={`/blog-details/${item.id}`}>
                    <img
                      src={item.imageUrl1}
                      alt=""
                      className="w-full  h-48 object-cov
                      er"
                    />
                  </Link>
                  <ul className="dz-badge-list">
                    <li>
                      <Link to={"#"} className="dz-badge">
                        {item.category}
                      </Link>
                    </li>
                  </ul>
                  <Link
                    to={`/blog-details/${item.id}`}
                    className="btn bg-[#5CB3FF] text-white"
                  >
                    Read More
                  </Link>
                </div>
                <div className="p-2">
                  <div className="author-wrappper">
                    <div className="flex gap-10 justify-center">
                      <div className="author-head">
                        <h6 className="flex gap-1">
                          <CiUser />
                          {item.username}
                        </h6>
                      </div>
                      <ul className="author-meta">
                        <li className="date flex gap-1 items-center">
                          <CiCalendarDate />
                          {item.date}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <h5 className="text-[#181C31] font-[24px] font-bold h-10">
                    <Link to={`/blog-details/${item.id}`}>
                      {item.title}...{" "}
                    </Link>
                  </h5>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default BlogGrid;
