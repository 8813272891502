import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { MenuListArray2 } from '../../Common/Navbar.js';
import logo from '../../assets/images/logo.png';
import { HiXMark } from 'react-icons/hi2';
import { RxHamburgerMenu } from "react-icons/rx";

const Headers = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header
      className={`header_in fixed top-0 left-0 right-0 z-10  shadow-md md:px-16 sm:py-3  py-2 overflow-x-hidden flex  items-center
       ${
        isMenuOpen ? 'justify-start   transition-all duration-500 ease-in bg-white' : 'justify-between bg-white'
      } `}
    >
      <div
        id="logo"
        className={isMenuOpen ? 'hidden  lg:block' : 'block '}
        onClick={() => setIsMenuOpen(false)} // Close menu when clicking on the logo
      >
        <Link to="/">
          <img src={logo}  alt="logo" className='sm:w-[160px] w-[120px] h-[50px] sm:h-[70px] mx-2' />
        </Link>
      </div>
      <nav className="hidden lg:flex  font-bold gap-10">
        {MenuListArray2.map((data, index) => (
          <Link key={index} to={data.to} className="">
            {data.title}
          </Link>
        ))}
      </nav>
      {/* Toggle button for mobile menu */}
      <div
        onClick={handleMenuToggle}
        className="absolute top-3 right-5  md:right-5  md:top-7 cursor-pointer lg:hidden  w-7 h-7"
      >
        {isMenuOpen ? <HiXMark size={30} /> : <RxHamburgerMenu size={30} />}
      </div>
      {/* Mobile menu */}
      {isMenuOpen && (
        <div className="lg:hidden px-4 py-2 transition-all duration-500 ease-in   ">
    <ul className={`font-bold ${isMenuOpen ? 'font-bold text-[12px]  transition-all duration-500 ease-in' : 'font-bold'}`}>
      {MenuListArray2.map((data, index) => (
        <li key={index} className='mb-4'>
          <Link to={data.to} onClick={() => setIsMenuOpen(false)} className=''>
            {data.title}
              {/* <hr className="border-b border-gray-300    mt-2"/> */}
            
          </Link>
        </li>
      ))}
    </ul>
    {/* Add the horizontal line here */}
  
  </div>
      )}
    </header>
  );
};

export default Headers;
