import React, { useContext, useEffect, useRef } from "react";
import { ThemeContext } from "../context/ThemeContext";
import CategoriesSlider from "./../components/Home/CategoriesSlider";
import TeamSlider from "./../components/Home/TeamSlider";
import PartnershipSlider from "./../components/Home/PartnershipSlider";
import RecentProjectsSlider from "./../components/Home/RecentProjectsSlider";
import TestimonialSlider from "./../components/Home/TestimonialSlider";
import StoryBlog from "./../components/Home/StoryBlog";
import NewsSlider from "./../components/Home/NewsSlider";
import DonateModal from "./../components/Modal/DonateModal";
//images
import { IMAGES } from "../constant/theme";
import ngo from "../assets/images/ngo.jpg";
import HomeSlider from "../layouts/Homepageslider";

const Home = () => {
  const modalRef = useRef();
  const { changeBackground, changePrimaryColor } = useContext(ThemeContext);
  useEffect(() => {
    changeBackground({ value: "default", label: "default" });
    changePrimaryColor("color-skin-1");
  }, []);

  return (
    <>
      <div className="relative overflow-x-hidden mt-2  ">
        <div className="page-content   bg-white ">
          <HomeSlider />

          <div className="content-inner-3 secti">
            <div className="container">
              <div
                className="section-head text-center wow fadeInUp"
                data-wow-delay="0.2s"
              >
                <h2 className=" text-[#181C31]  mt-[10px] font-bold text-[30px]">
                  CATEGORIES
                </h2>
              </div>

              <CategoriesSlider />
            </div>
            <img src={IMAGES.Shape1} className="shape-1" alt="shape" />
            <img src={IMAGES.Shape2} className="shape-2" alt="shape" />
            <img src={IMAGES.Shape3} className="shape-3" alt="shape" />
            <img src={IMAGES.Shape5} className="shape-4" alt="shape" />
            <img src={IMAGES.Shape6} className="shape-5" alt="shape" />
          </div>
          <section className="content-inner-3">
            <div className="container">
              <StoryBlog />
            </div>
          </section>
          <section
            className="clients-wrapper wow fadeInUp"
            data-wow-delay="0.2s"
          >
            <div className="container">
              <div className="section-head text-center">
                <h4 className="title text-[#181C31] font-bold text-[30px]">
                  OUR DONORS
                </h4>
              </div>
              <PartnershipSlider />
            </div>
          </section>

          <section className="content-inner-3 overflow-hidden section-pattren1">
            <div className="container">
              <div
                className="section-head text-center wow fadeInUp"
                data-wow-delay="0.2s"
              >
                <h2 className="text-[#181C31] font-bold text-[30px]">
                  RECENT PROJECTS
                </h2>
              </div>
              <RecentProjectsSlider />
            </div>
          </section>
          <section className="testimonial-wrapper1 content-inner">
            <div
              className="section-head text-center wow fadeInUp"
              data-wow-delay="0.2s"
            >
              <h2 className="title font-bold">Testimonials</h2>
            </div>
            <TestimonialSlider />
          </section>

          <section className="content-inner-3 section-pattren1">
            <div className="container">
              <div
                className="section-head text-center wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <h2 className="text-[#181C31] font-bold text-[30px]">
                  TEAM MEMBER
                </h2>
              </div>
              <TeamSlider />
            </div>
            <img src={IMAGES.Shape1} className="shape-1 move-1" alt="shape" />
            <img src={IMAGES.Shape2} className="shape-4 move-2" alt="shape" />
            <img src={IMAGES.Shape3} className="shape-6 move-1" alt="shape" />
            <img src={IMAGES.Shape5} className="shape-2 rotating" alt="shape" />
            <img src={IMAGES.Shape6} className="shape-5 rotating" alt="shape" />
            <img src={IMAGES.Shape6} className="shape-3 rotating" alt="shape" />
          </section>

          <section className="content-inner-2 section-pattren1">
            <div className="container">
              <div
                className="section-head text-center wow fadeInUp"
                data-wow-delay="0.2s"
              >
                <h2 className="title text-[#181C31] font-bold text-[30px]">
                  BLOG
                </h2>
              </div>
              <NewsSlider />
            </div>
            <img src={IMAGES.Shape2} className="shape-6 move-2" alt="shape" />
            <img src={IMAGES.Shape3} className="shape-5 move-1" alt="shape" />
            <img src={IMAGES.Shape5} className="shape-1 rotating" alt="shape" />
            <img src={IMAGES.Shape1} className="shape-3 move-1" alt="shape" />
            <img src={IMAGES.Shape6} className="shape-4 rotating" alt="shape" />
            <img src={IMAGES.Shape6} className="shape-2 rotating" alt="shape" />
          </section>
        </div>
      </div>

      <DonateModal ref={modalRef} />
    </>
  );
};
export default Home;
