import React, { useEffect, useState } from 'react';
import { getDatabase, onValue, ref } from 'firebase/database';
import { app } from "../../firebase";
import { AdvanceTable } from './AdvanceTable';

export const ViewContact = () => {
  const [FounderData, setFounderData] = useState(null);

  useEffect(() => {
    const db = getDatabase(app);
    const founderRef = ref(db, 'Founder');

    onValue(founderRef, (snapshot) => {
      const data = snapshot.val();
      setFounderData(data); // Update the state with the fetched data
    });
  }, []);

  // Define columns for AdvanceTable component
  const columns = [
    {
      header: 'Name',
      accessor: (founder) => founder.name,
    },
    {
      header: 'Position',
      accessor: (founder) => founder.position,
    },
  ];

  return (
    <main className="mt-20 ml-20">
      <h1 className="w-fit mx-auto text-blue-700">View Founder</h1>
      <span className="flex w-[120px] h-[2px] bg-[#e1e1e1] mx-auto mb-4">
        <em className="w-[60px] h-[2px] bg-blue-700 mx-auto" />
      </span>

      <div className='ml-40'>
        {FounderData && Object.entries(FounderData).length > 0 && (
          <AdvanceTable columns={columns} data={Object.values(FounderData)} />
        )}
      </div>
    </main>
  );
};
