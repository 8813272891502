import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import { collection, deleteDoc, doc, getDocs } from "firebase/firestore";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Buttons from "../../Common/Buttons";
import { FaRegEdit } from "react-icons/fa";
import { DeleteModal } from "../../Common/DeleteModal";

const iconClass = "text-xl";
const tableData = "border-b border-[#eee] py-3 px-4 ";
const heading =
  "py-4 text-xl font-bold text-gray-800 pl-4 p-10   bg-blue-100";

export const ViewTestimonial = () => {
  const [testimonialData, setTestimonialData] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTestimonialData = async () => {
      const TestimonialCollection = collection(db, "Testimonials");
      const snapshot = await getDocs(TestimonialCollection);
      const data = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setTestimonialData(data);
    };
    fetchTestimonialData();
  }, []);

  const handleDelete = (id) => {
    setSelectedItemId(id);
    setIsDeleteModalOpen(true);
  };

  const confirmDelete = async () => {
    try {
      const testimonialRef = doc(db, "Testimonials", selectedItemId);
      await deleteDoc(testimonialRef);
      setTestimonialData(testimonialData.filter((item) => item.id !== selectedItemId));
      setIsDeleteModalOpen(false);
    } catch (error) {
      console.log("error deleting document", error);
    }
  };

  const handleEdit = (id) => {
    try {
      const foundTestimonial = testimonialData.find((testimonial) => testimonial.id === id);
      navigate(`/edittestimonial/${id}`, { state: { editData: foundTestimonial } });
    } catch (error) {
      console.log("error navigating to edit page", error);
    }
  };

  return (
    <>
      <div className="ml-40  px-5 pt-6 pb-2.5 ">
        <div className="flex justify-end mb-10">
          <Buttons type="button" variant="secondary" to="/addtestimonial">
            Add
          </Buttons>
        </div>
        <div className="rounded-sm  bg-white  shadow-default  dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <div className="overflow-x-auto  border  border-gray-100">
            <table className="w-full table-auto border-collapse  ">
              <thead>
                <tr className="bg-gray-200 text-left ">
                  <th className={heading}>Image</th>
                  <th className={heading}>Author Name</th>
                  <th className={heading}>Description</th>
                  <th className={heading}>Position</th>
                  <th className={heading}>Action</th>
                </tr>
              </thead>
              <tbody>
                {testimonialData && testimonialData.length > 0 ? (
                  testimonialData.map((testimonialData, key) => (
                    <tr
                      key={key}
                      className={
                        key % 2 === 0 ? "bg-gray-100 " : ""
                      }
                    >
                      <td className={tableData}>
                        <img
                          alt="founderimage"
                          src={testimonialData.imageUrl}
                          className="h-20 w-20  object-cover border border-gray-100"
                        />
                      </td>
                      <td className={tableData}>
                        <p className="text-black text-xl">{testimonialData.author}</p>
                      </td>
                      <td className={tableData}>
                        <p className="text-black text-xl">{testimonialData.description.slice(0,50)}</p>
                      </td>
                      <td className={tableData}>
                        <p className="text-black text-xl">{testimonialData.position}</p>
                      </td>
                      <td className={tableData}>
                        <div className="flex items-center space-x-3.5">
                          <span className="hover:text-primary cursor-pointer">
                            <FaRegEdit
                              className={iconClass}
                              onClick={() => handleEdit(testimonialData.id)}
                            />
                          </span>
                          <span
                            className="hover:text-primary cursor-pointer"
                            onClick={() => handleDelete(testimonialData.id)}
                          >
                            <MdOutlineDeleteOutline className={iconClass} />
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : 
                (
                  <tr>
                    <td colSpan="5" className="text-center py-5">
                      No data available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        {/* Add the DeleteModal component here */}
        <DeleteModal
          isModalOpen={isDeleteModalOpen}
          closeModal={() => setIsDeleteModalOpen(false)}
          titleName={`Testimonial item ${selectedItemId}`} // Adjust the title or details accordingly
          handleDelete={confirmDelete}
        />
      </div>
    </>
  );
};
