import React, { useEffect, useState } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { CiCalendarDate } from "react-icons/ci";
import { CiUser } from "react-icons/ci";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
//import { LazyLoadImage } from 'react-lazy-load-image-component';
//Images
import pic2 from './../../assets/images/blog/blog-grid/pic2.jpg';
import pic3 from './../../assets/images/blog/blog-grid/pic3.jpg';
import pic4 from './../../assets/images/blog/blog-grid/pic4.jpg';
import avat2 from './../../assets/images/avatar/avatar2.jpg';
import avat3 from './../../assets/images/avatar/avatar3.jpg';
import avat4 from './../../assets/images/avatar/avatar4.jpg';


import { Autoplay } from "swiper/modules";
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import { News } from '../../Common/Dashboard_index';

const dataBlog = [
	{subtitle:"Hawkins Junior",image: pic4, image2:avat2, title:"New vaccine for cattle calf loss learned", tage:"HEALTH"},
	{subtitle:"Tom wilson",image: pic3, image2:avat3, title:"4 Things parents learned for they jids in 2020",tage:"TECHNOLOGY"},
	{subtitle:"Adam Jordon",image: pic2, image2:avat4, title:"He Created the Web. Now He’s Out to Remake",tage:"EDUCATION"},
];

const NewsSlider = () => {
    const [NewsData,setNewsData]=useState([]);
    const [currentdata,setCurrentData]=useState();
    useEffect(()=>{
        const fetchNews=async()=>{
            const NewsCollection=collection(db,'News');
            const snapshot=await getDocs(NewsCollection);
            const Newsdatas=snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setCurrentData(Newsdatas);
            setNewsData(Newsdatas);
        };
        fetchNews();
        console.log(NewsData);
    },[])

    return (
        <>             
            <Swiper className="blog-slider-full"
				speed= {1500}
				//parallax= {true}
				slidesPerView= {3}
				spaceBetween= {10}
				loop={true}
				autoplay= {{
				   delay: 3000,
				}}
				modules={[ Autoplay ]}
                breakpoints = {{
					1200: {
                        slidesPerView: 3,
                    },
                    992: {
                        slidesPerView: 3,
                    },
                    768: {
                        slidesPerView: 2,
                    },
                    575: {
                        slidesPerView: 2,
                    },
                    320: {
                        slidesPerView: 1,
                    },
				}}
			>	
               
				{NewsData.map((item,i)=>(
					<SwiperSlide key={i}>						
                        <div className="dz-card relative style-1 w-full   border border-gray-50 shadow-sm lg:w-[23rem] sm:w-[19rem] wow fadeInUp " data-wow-delay="0.2s">
                            <div className="dz-media  shadow-gray-300">
                                <Link to={`/blog-details/${item.id}`}><img src={item.imageUrl1} alt="" className='w:full lg:w-[23rem] sm:w-[19rem] h-52' /></Link>
                                <ul className="dz-badge-list">
                                    <li><Link to={"#"} className="dz-badge">{item.category}</Link></li>
                                </ul>
                                <Link to={`/blog-details/${item.id}`} className="btn bg-[#5CB3FF] text-white">Read More</Link>
                            </div>
                            <div className="p-3 ">
                                <h5 className="dz-title font-bold h-6 truncate  "><Link to={`/blog-details/${item.id}`}>{item.title}... </Link></h5>
                             
                                <div className="author-wrappper ">
                                  
                                    <div className="flex justify-between gap-12  ">
                                        <div className="author-head">
                                            <h6 className="author-name flex gap-1 font-bold"><CiUser />{item.username}</h6>
                                        </div>
                                        <ul className="author-meta">
                                            <li className="date flex gap-1 font-bold" ><CiCalendarDate />{item.date}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
					</SwiperSlide>
				))}				
			</Swiper>
            
        </>
    );
};


export default NewsSlider;