import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import { collection, deleteDoc, doc, getDocs } from "firebase/firestore";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Buttons from "../../Common/Buttons";
import { FaRegEye } from "react-icons/fa";
import { DeleteModal } from "../../Common/DeleteModal";
const iconClass = "text-xl";
const tableData = "border-b border-[#eee] py-3 px-4 ";

const heading =
  "py-4 text-xl font-bold text-gray-800 pl-4 p-10   bg-blue-100";

export const ViewFounder = () => {
  const [founderData, setFounderData] = useState(null);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchFounderData = async () => {
      const founderCollection = collection(db, "Founder");
      const snapshot = await getDocs(founderCollection);
      const data = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setFounderData(data);
    };
    fetchFounderData();
  }, []);

  const handleDelete = (id) => {
    setSelectedItemId(id);
    setDeleteModalOpen(true);
  };

  const confirmDelete = async () => {
    try {
      const founderRef = doc(db, "Founder", selectedItemId);
      await deleteDoc(founderRef);
      setFounderData(founderData.filter((item) => item.id !== selectedItemId));
    } catch (error) {
      console.log("error deleting document", error);
    } finally {
      setDeleteModalOpen(false);
    }
  };

  const handleEdit = (id) => {
    try {
      const foundFounder = founderData.find((founder) => founder.id === id);
      navigate(`/editfounder/${id}`, { state: { editData: foundFounder } });
    } catch (error) {
      console.log("error navigating to edit page", error);
    }
  };

  return (
    <div className="ml-40  px-5 pt-6 pb-2.5 ">
      <div className="flex justify-end mb-10">
        <Buttons type="button" variant="secondary" to="/addfounder">
          Add
        </Buttons>
      </div>
      <div className="rounded-sm  bg-white  shadow-default  sm:px-7.5 xl:pb-1">
        <div className="overflow-x-auto  border  border-gray-100">
          <table className="w-full table-auto border-collapse  ">
            <thead>
              <tr className="bg-gray-200 text-left ">
                <th className={heading}>Upload Image</th>
                <th className={heading}>Name</th>
                <th className={heading}>Position</th>
                <th className={heading}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {founderData && founderData.length > 0 ? (
                founderData.map((partnershipData, key) => (
                  <tr
                    key={key}
                    className={
                      key % 2 === 0 ? "bg-gray-100 dark:bg-gray-200" : ""
                    }
                  >
                    <td className={tableData}>
                    <img
                       alt="founderimage"
                      src={partnershipData.imageUrl1}
                      className="h-20 w-20 object-cover border border-gray-100"
                    />

                    </td>
                    <td className={tableData}>
                      <p className="text-black text-xl">{partnershipData.name}</p>
                    </td>
                    <td className={tableData}>
                      <p className="text-black text-xl">{partnershipData.position}</p>
                    </td>
                    <td className={tableData}>
                      <div className="flex items-center space-x-3.5">
                        <span className="hover:text-primary cursor-pointer">
                          <FaRegEye
                          className={iconClass}
                            onClick={() => handleEdit(partnershipData.id)}
                          />
                        </span>
                        <span
                          className="hover:text-primary cursor-pointer"
                          onClick={() => handleDelete(partnershipData.id)}
                        >
                          <MdOutlineDeleteOutline  className={iconClass}/>
                        </span>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="2" className="text-center text-[20px] py-5">
                    No Data Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {isDeleteModalOpen && (
        <DeleteModal
          isModalOpen={isDeleteModalOpen}
          closeModal={() => setDeleteModalOpen(false)}
          titleName="Founder"  // Pass the appropriate title here
          handleDelete={confirmDelete}
        />
      )}
    </div>
  );
};
