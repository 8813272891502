import React, { useEffect, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
//import { LazyLoadImage } from 'react-lazy-load-image-component';
// Import Swiper styles
import "swiper/css";
//Images
import logo1 from "./../../assets/images/clients-logo/logo1.png";
import logo2 from "./../../assets/images/clients-logo/logo2.png";
import logo3 from "./../../assets/images/clients-logo/logo3.png";
import logo4 from "./../../assets/images/clients-logo/logo4.png";
import logo5 from "./../../assets/images/clients-logo/logo5.png";
// import Swiper core and required modules
import { Autoplay } from "swiper/modules";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";

//SwiperCore.use([EffectCoverflow,Pagination]);

const dataBlog = [
  { image: logo1 },
  { image: logo2 },
  { image: logo3 },
  { image: logo4 },
  { image: logo5 },
];

const PartnershipSlider = () => {
  const [banner, setBanner] = useState([]);
  useEffect(() => {
    const fetchPartnership = async () => {
      const PartnershipCollection = collection(db, "Partnership");
      const snapshot = await getDocs(PartnershipCollection);
      const pqrtnershipData = snapshot.docs.map((doc) => doc.data());
      setBanner(pqrtnershipData);
    };
    fetchPartnership();
  }, []);
  console.log(banner);
  return (
    <>
      <Swiper
        className="clients-swiper"
        speed={1000}
        //parallax= {true}
        slidesPerView={2}
        spaceBetween={20}
        loop={true}
        autoplay={{
          delay: 1000,
        }}
        modules={[Autoplay]}
        breakpoints={{
          1191: {
            slidesPerView: 7,
          },
          992: {
            slidesPerView: 6,
          },
          768: {
            slidesPerView: 4,
          },
          575: {
            slidesPerView: 3,
          },
          320: {
            slidesPerView: 2,
          },
        }}
      >
        {banner.map((banner, i) => (
          <SwiperSlide key={i}>
            <div className="clients-logo rounded-[2px]">
              <img className="logo-main" src={banner.imageUrl} alt="" className=" h-40 w-96 rounded-[2px] object-cover" />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default PartnershipSlider;
