import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import React, { useEffect, useState } from 'react'
import { db, storage } from '../../firebase';
import {  doc, getDoc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { useNavigate, useParams } from 'react-router-dom';
import { CounterData } from '../../Common/Dashboard_index';

export const EditCounter = () => {
  const [form, setForm] = useState({
    title:"",
    number:""
    // Add other fields from your document here
  });
  const { id } = useParams();
  const [error, setError] = useState({});
  const [file1, setFile1] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const Doc = await getDoc(doc(db, 'Counter', id));
        if (Doc.exists()) {
          setForm(Doc.data());
        } else {
          console.error('Counter not found');
        }
      } catch (error) {
        console.error('Error fetching Counter data:', error);
      }
    };

    fetchData();
  }, [id]);
  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (files && files.length > 0) {
      const file = files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        setForm((prevData) => ({
          ...prevData,
          imageUrl: reader.result,
        }));
      };

      reader.readAsDataURL(file);
    } else {
        setForm((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isEmptyField = Object.values(form).some((value) => value === '');
    if (isEmptyField) {
      setError({ submit: 'All Fields are required' });
      alert('All fields are required');
      return;
    }

    setIsSubmit(true);

    try {
      // Assuming initialData.id is the document ID you want to update
      const counterRef = doc(db, 'Counter', id);

      await updateDoc(counterRef, {
        ...form,
        timestamp: serverTimestamp(),
      });

      alert('Data is updated in the database');
      navigate('/viewcounter');
    } catch (error) {
      console.error('Error updating document: ', error);
    }
  };
  return (
    <div>
        <>
        <div>
    <main className="mt-20 ml-20 ">
      <h1 className="w-fit mx-auto text-blue-700"> Edit Counter</h1>
      <span className="flex w-[120px] h-[2px] bg-[#e1e1e1]  mx-auto mb-4">
        <em className="w-[60px] h-[2px] bg-blue-700 mx-auto" />
      </span>
      <form className="w-[60%] h-auto  p-10 bg-slate-50 shadow-lg mx-auto">
        <div className="flex flex-wrap justify-between gap-4">
        {CounterData.map((item)=>(
          <div key={item}>
          <input
            onChange={handleChange}
            value={form[item.name]}
            type={item.type}
            name={item.name} // Fix: Specify the name attribute
            className="p-3 my-3 bg-gray-100  rounded"
            placeholder={item.placeH}
            required
          />
               </div>
        ))}
        </div>

        <button
          onClick={handleSubmit}
          type="submit"
          className="bg-blue-400 py-2 px-20 rounded text-black mt-5"
        >
          Update
        </button>
      </form>
    </main>
    </div>
          
        </>
    </div>
  )
}
