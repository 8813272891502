import { React, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

//import { LazyLoadImage } from 'react-lazy-load-image-component';

// Import Swiper styles+
//import "swiper/css";

//Images
import pic1 from "./../../assets/images/testimonials/pic1.jpg";
import pic2 from "./../../assets/images/testimonials/pic2.jpg";
import pic3 from "./../../assets/images/testimonials/pic3.jpg";

// import Swiper core and required modules
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";

//SwiperCore.use([EffectCoverflow,Pagination]);

const dataBlog = [
  { image: pic1, title: "Johan Lee" },
  { image: pic2, title: "Lee Jordon" },
  { image: pic3, title: "Alex Costa" },
];

const TestimonialSlider = () => {
  const [testimonial, setTestimonial] = useState([]);
  useEffect(() => {
    const fetchTestimonial = async () => {
      const testimonialCOllection = collection(db, "Testimonials");
      const snapshot = await getDocs(testimonialCOllection);
      const testimonialData = snapshot.docs.map((doc) => doc.data());
      setTestimonial(testimonialData);
    };
    fetchTestimonial();
    console.log(fetchTestimonial);
  }, []);
  // const navigationPrevRef = useRef(null)
  // const navigationNextRef = useRef(null)
  return (
    <>
      <Swiper
        className="  mx-4 md:pl-44 "
        speed={10000}
        //parallax= {true}
        slidesPerView={2}
        spaceBetween={20}
        // setWrapperSize={200}
        centeredSlides={true}
        loop={true}
        autoplay={{
          delay: 1000,
        }}
        modules={[Autoplay, Navigation, Pagination]}
        breakpoints={{
          1200: {
            slidesPerView: 3,
          },
          992: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 1,
          },

          320: {
            slidesPerView: 1,
          },
        }}
        onSwiper={(swiper) => {
          setTimeout(() => {
            // swiper.params.navigation.prevEl = navigationPrevRef.current
            // swiper.params.navigation.nextEl = navigationNextRef.current
            // swiper.navigation.destroy()
            // swiper.navigation.init()
            // swiper.navigation.update()
          });
        }}
      >
        <div className=" bg-red-200   ">
          {testimonial.map((testimonial, i) => (
            <SwiperSlide key={i}>
              <div class="flex gap-4 px-4 md:px-44 ">
                <div class="w-full md:w-[60rem] rounded p-3 bg-gray-300 flex flex-col md:flex-row">
                  <div class="testimonial-pic h w-20 h-20 rounded-full flex-shrink-0">
                    <img
                      src={testimonial.imageUrl}
                      alt=""
                      class="object-cover rounded-full w-full h-full"
                    />
                  </div>
                  <div class="testimonial-details mx-2 flex flex-col justify-center item-center">
                    <div class="testimonial-text line-clamp-1  text-wrap truncate flex-shrink">
                      <p>{testimonial.description}</p>
                    </div>
                    <div>
                      <h5 class="testimonial-name font-bold">{testimonial.author}</h5>
                      <span class="testimonial-position line-clamp-1">
                        {testimonial.position}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </div>
        <div className="swiper-btn container swiper-btn-center-lr">
          {/* <div className="test-swiper-prev btn-prev style-1" ref={navigationPrevRef}><i className="fa fa-arrow-left-long"></i></div>
					<div className="test-swiper-next btn-next style-1" ref={navigationNextRef}><i className="fa fa-arrow-right-long"></i></div> */}
        </div>
      </Swiper>
    </>
  );
};

export default TestimonialSlider;
