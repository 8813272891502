import React, { useState } from 'react';


import ProjectMasonry from '../components/Project/ProjectMasonry';

import bgs from "../assets/images/projects1.jpg"

const Project = () => {
    return (
        <>
            <div className="page-content bg-white overflow-x-hidden  min-h-[100vh]">

                <div className='relative'>
                    <img src={bgs} alt="" className='w-full h-[20rem] md:h-[32rem] object-cover ' />
                    {/* Background color overlay */}
                    <div className='absolute inset-0 bg-[#6b696a] bg-opacity-50'></div>
                    {/* Content */}
                    <span className='absolute md:bottom-[12rem] bottom-[8rem] flex bg-transparent w-full md:h-20 p-2 rounded text-center text-black font-extrabold text-2xl md:text-6xl justify-center '>
                        <div className="dz-bnr-inr-entry  w-full">

                            <nav aria-label="breadcrumb" className="breadcrumb-row">
                                <ul className="breadcrumb">

                                    <li className="breadcrumb-item active">Project</li>
                                </ul>
                            </nav>
                        </div>
                    </span>
                </div>
                <div className="find-bx-wrapper">
                    <div className="container">


                    </div>
                </div>
                <section className="content-inner-2 ">
                    <div className="container ">
                        <ProjectMasonry />
                    </div>
                </section>

            </div>
        </>
    );
};

export default Project;
