import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import { collection, deleteDoc, doc, getDocs } from "firebase/firestore";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Buttons from "../../Common/Buttons";
import { FaRegEdit } from "react-icons/fa";
import { DeleteModal } from "../../Common/DeleteModal";

const iconClass = "text-xl";
const tableData = "border-b border-[#eee] py-3 px-4 ";
const heading = "py-4 text-xl font-bold text-gray-800 pl-4 p-10   bg-blue-100";

export const ViewSuccessStory = () => {
  const [SuccessData, setSuccessData] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSuccessData = async () => {
      const NewsCollection = collection(db, "SuccessStory");
      const snapshot = await getDocs(NewsCollection);
      const data = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setSuccessData(data);
    };
    fetchSuccessData();
  }, []);

  const handleDelete = (id) => {
    setSelectedItemId(id);
    setIsDeleteModalOpen(true);
  };

  const confirmDelete = async () => {
    try {
      const ProjectRef = doc(db, "SuccessStory", selectedItemId);
      await deleteDoc(ProjectRef);
      setSuccessData(SuccessData.filter((item) => item.id !== selectedItemId));
      setIsDeleteModalOpen(false);
    } catch (error) {
      console.log("error deleting document", error);
    }
  };

  const handleEdit = (id) => {
    try {
      const foundProject = SuccessData.find((news) => news.id === id);
      navigate(`/editsuccessstory/${id}`, { state: { editData: foundProject } });
    } catch (error) {
      console.log("error navigating to edit page", error);
    }
  };

  return (
    <>
      <div className="ml-40  px-5 pt-6 pb-2.5 ">
        <div className="flex justify-end mb-10">
          <Buttons type="button" variant="secondary" to="/addsuccessstory">
            Add
          </Buttons>
        </div>
        <div className="rounded-sm  bg-white  shadow-default  dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <div className="overflow-x-auto  border  border-gray-100">
            <table className="w-full table-auto border-collapse  ">
              <thead>
                <tr className="bg-gray-200 text-left ">
                  <th className={heading}>Image</th>
                  <th className={heading}>Image2</th>
                  <th className={heading}>Author</th>
                  <th className={heading}>Title</th>
                  <th className={heading}>Address</th>
                  <th className={heading}>No_campaign</th>
                  <th className={heading}>Description</th>
                  <th className={heading}>Action</th>
                </tr>
              </thead>
              <tbody>
                {SuccessData && SuccessData.length > 0 ? (
                  SuccessData.map((SuccessData, key) => (
                    <tr key={key} className={key % 2 === 0 ? "bg-gray-100 " : ""}>
                      <td className={tableData}>
                        <img
                          alt="founderimage"
                          src={SuccessData.imageUrl1}
                          className="h-20  w-20  object-cover border border-gray-100"
                        />
                      </td>
                      <td className={tableData}>
                        <img
                          alt="founderimage"
                          src={SuccessData.imageUrl2}
                          className="h-20 w-20  object-cover border border-gray-100"
                        />
                      </td>
                      <td className={tableData}>
                        <p className="text-black text-xl">{SuccessData.author}</p>
                      </td>
                      <td className={tableData}>
                        <p className="text-black text-xl">{SuccessData.title.slice(0, 10)}</p>
                      </td>
                      <td className={tableData}>
                        <p className="text-black text-xl">{SuccessData.Address}</p>
                      </td>
                      <td className={tableData}>
                        <p className="text-black text-xl">{SuccessData.no_campaign}</p>
                      </td>
                      <td className={tableData}>
                        <p className="text-black text-xl">{SuccessData.description.slice(0, 30)}</p>
                      </td>
                      <td className={tableData}>
                        <div className="flex items-center space-x-3.5">
                          <span className="hover:text-primary cursor-pointer">
                            <FaRegEdit className={iconClass} onClick={() => handleEdit(SuccessData.id)} />
                          </span>
                          <span
                            className="hover:text-primary cursor-pointer"
                            onClick={() => handleDelete(SuccessData.id)}
                          >
                            <MdOutlineDeleteOutline className={iconClass} />
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8" className="text-center py-5">
                      No data available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        {/* Add the DeleteModal component here */}
        {/* Make sure to replace DeleteModal with the actual component name */}
        <DeleteModal
          isModalOpen={isDeleteModalOpen}
          closeModal={() => setIsDeleteModalOpen(false)}
          titleName={`SuccessStory item ${selectedItemId}`}  // Adjust the title or details accordingly
          handleDelete={confirmDelete}
        />
      </div>
    </>
  );
};

