import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import { collection, deleteDoc, doc, getDocs } from "firebase/firestore";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Buttons from "../../Common/Buttons";
import { FaRegEdit} from "react-icons/fa";
const iconClass = "text-xl";

const tableData = "border-b border-[#eee] py-3 px-4 ";
const heading =
  "py-4 text-xl font-bold text-gray-800 pl-4 p-10   bg-blue-100";
export const ViewCounter = () => {
  const [CounterData, setCounterData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCounterData = async () => {
      const CounterCollection = collection(db, "Counter");
      const snapshot = await getDocs(CounterCollection);
      const data = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setCounterData(data);
    };
    fetchCounterData();
  }, []);

  const handleDelete = async (id) => {
    try {
      const counterRef = doc(db, "Counter", id);
      await deleteDoc(counterRef);
      setCounterData(CounterData.filter((item) => item.id !== id));
    } catch (error) {
      console.log("error deleting document", error);
    }
  };
  const handleEdit = (id) => {
    try {

      const foundCounter = CounterData?.find((counter) => counter.id === id);

      navigate(`/editcounter/${id}`, { state: { editData: foundCounter } });
    } catch (error) {
      console.log("error navigating to edit page", error);
    }
  };

  return (
    <div className="ml-40  px-5 pt-6 pb-2.5 ">
      <div className="flex justify-end mb-10">
        <Buttons type="button" variant="secondary" to="/addcounter">
          Add
        </Buttons>
      </div>
      <div className="rounded-sm  bg-white  shadow-default  dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
        <div className="overflow-x-auto  border  border-gray-100">
          <table className="w-full table-auto border-collapse  ">
            <thead>
              <tr className="bg-gray-200 text-left ">
                <th className={heading}>Title</th>
                <th className={heading}>Number</th>
                <th className={heading}>Action</th>
              </tr>
            </thead>
            <tbody>
              {CounterData && CounterData.length > 0 ? (
                CounterData.map((CounterData, key) => (
                  <tr
                    key={key}
                    className={
                      key % 2 === 0 ? "bg-gray-100 " : ""
                    }
                  >
                   <td className={tableData}>
                      <p className="text-black text-xl">{CounterData.title}</p>
                    </td>
                    <td className={tableData}>
                      <p className="text-black text-xl">{CounterData.number}</p>
                    </td>
                    <td className={tableData}>
                      <div className="flex items-center space-x-3.5">
                        <span className="hover:text-primary cursor-pointer">
                          <FaRegEdit
                          className={iconClass}
                            onClick={() => handleEdit(CounterData.id)}
                          />
                        </span>
                        <span
                          className="hover:text-primary cursor-pointer"
                          onClick={() => handleDelete(CounterData.id)}
                        >
                          <MdOutlineDeleteOutline className={iconClass}/>
                        </span>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="2" className="text-center text-[20px] py-5">
                    No Data Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
