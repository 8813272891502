import React, { useEffect, useState } from 'react';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { db, storage } from '../../firebase';
import { useNavigate } from 'react-router-dom';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { RecentProject } from '../../Common/Dashboard_index';
import Loader from '../../Common/Loader';
import Modal from '../../Common/Modal';

export const AddProject = () => {
  const navigate = useNavigate();
  const [form, setForm] = useState({
    author: '',
    imageUrl1: '',
    imageUrl2: '',
    title: '',
    description: '',
    category: '',
    no_campaign: '',
    Address: '',
  });

  const [file1, setFile1] = useState(null);
  const [file2, setFile2] = useState(null);

  const [error, setError] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const [progress, setProgress] = useState(null);
  const [uploadedImageUrl1, setUploadedImageUrl1] = useState(null);
  const [uploadedImageUrl2, setUploadedImageUrl2] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const uploadFile = (file, setFile, imageUrlKey, setUploadedImageUrl) => {
      const storageRef = ref(storage, file.name);
      const uploadFile = uploadBytesResumable(storageRef, file);

      uploadFile.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(progress);
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadFile.snapshot.ref).then((downloadURL) => {
            setForm((prevForm) => ({ ...prevForm, [imageUrlKey]: downloadURL }));
            setUploadedImageUrl(downloadURL);
          });
        }
      );
    };

    if (file1) {
      uploadFile(file1, setFile1, 'imageUrl1', setUploadedImageUrl1);
    }

    if (file2) {
      uploadFile(file2, setFile2, 'imageUrl2', setUploadedImageUrl2);
    }
  }, [file1, file2]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isEmptyField = Object.values(form).some((value) => value === '');
    if (isEmptyField) {
      setError({ submit: 'All Fields are required' });
      alert('All fields are required');
      return;
    }

    setIsSubmit(true);
    setIsLoading(true);
    try {
      const docRef = await addDoc(collection(db, 'Project'), {
        ...form,
        timestamp: serverTimestamp(),
      });

      setModalMessage('Data is stored in the database');
      setIsModalOpen(true);
      setTimeout(() => {
        setIsModalOpen(false);
        setIsLoading(false);
        navigate('/');
      }, 3000); // Close modal and navigate after 3 seconds
    } catch (error) {
      console.error('Error adding document: ', error);
      setModalMessage('Error: Failed to add data to the database');
      setIsModalOpen(true);
      setIsLoading(false);
    }
  };

  return (
    <main className="mt-20 ml-20 ">
      <h1 className="w-fit mx-auto text-blue-700">Add Projects</h1>
      <span className="flex w-[120px] h-[2px] bg-[#e1e1e1]  mx-auto mb-4">
        <em className="w-[60px] h-[2px] bg-blue-700 mx-auto" />
      </span>
      <form className="w-[80%] h-auto  p-10 bg-slate-50 shadow-lg mx-auto">
        <div className="flex flex-wrap justify-between gap-4">
          {RecentProject.map((item) => (
            <div key={item.name} className='flex flex-col'>
            <label className="text-sm font-semibold text-black">{item.placeH}</label>
              <input
                onChange={handleChange}
                value={form[item.name]}
                type={item.type}
                name={item.name}
                className="p-3 my-3 w-96 bg-gray-100  rounded"
                placeholder={item.placeH}
                required
              />
            </div>
          ))}
          <textarea
            id="description"
            name="description"
            rows="4"
            cols="50"
            placeholder="write description"
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className='flex  gap-4 mt-4'>
          {/* Image upload box for imageUrl1 */}
          <div className="flex flex-wrap gap-4">
            <label htmlFor="fileInput1" className="cursor-pointer  flex items-center justify-center border border-dashed border-[#1475cf] min-h-[120px] min-w-[120px] max-h-[120px] max-w-[120px]">
              <input
                type="file"
                id="fileInput1"
                accept="image/*"
                className="hidden"
                onChange={(e) => setFile1(e.target.files[0])}
              />
              <span className="text-[12px] text-center font-bold">(Main Image)</span>
            </label>
            {uploadedImageUrl1 && (
              <img
                src={uploadedImageUrl1}
                alt="Uploaded"
                className="w-[120px] h-[120px] border  border-blue-600 object-cover"
              />
            )}
          </div>

          <div className="flex flex-wrap gap-4">
            {/* Image upload box for imageUrl2 */}
            <label htmlFor="fileInput2" className="cursor-pointer flex items-center justify-center border border-dashed border-[#1475cf] min-h-[120px] min-w-[120px] max-h-[120px] max-w-[120px]">
              <input
                type="file"
                id="fileInput2"
                accept="image/*"
                className="hidden"
                onChange={(e) => setFile2(e.target.files[0])}
              />
              <span className="text-[12px] text-center font-bold">(Choose pic 2)</span>
            </label>
            {uploadedImageUrl2 && (
              <img
                src={uploadedImageUrl2}
                alt="Uploaded"
                className="w-[120px] h-[120px] border border-blue-600 object-cover"
              />
            )}
          </div>
        </div>

        <button
          onClick={handleSubmit}
          type="submit"
          className="bg-blue-400 py-2 px-20 rounded text-black mt-5"
        >
          {isLoading ? <Loader /> : 'Create'}
        </button>
      </form>

      {isModalOpen && (
        <Modal onClose={() => setIsModalOpen(false)}>
          <p>{modalMessage}</p>
        </Modal>
      )}
    </main>
  );
};
